@import url('../../config/colors.css');

.HeaderContainer {
  height: 78px;
  background-color: var(--colors-white-absolute);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderImage {
  display: flex;
  flex-direction: row;
}

.HeaderImageWithTitle {
  position: absolute;
  left: 20px;
}

.HeaderImageNoTitle {
  position: absolute;
}

.HeaderImageTitle {
  margin-left: 10px;
  font-family: 'Nunito-Light';
  font-size: 30px;
}

.HeaderTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
}

.HeaderDropShadow {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

@media (max-width: 800px) {
  .HeaderTitle {
    visibility: hidden;
  }
}