@import url('../../config/colors.css');

.AlertContainer {
  width: 400px;
  background-color: var(--colors-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.AlertTitle {
  font-family: 'Nunito-Bold';
  font-size: 24px;
  color: var(--colors-secondary-alternative);
}

.AlertText {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  margin-left: 50px;
  margin-right: 50px;
}

.AlertButton {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-white-absolute);
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 25px;
  min-width: 133px;
  background: var(--colors-secondary-alternative);
  border: none;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
}

.AlertButtonCancel {
  background: var(--colors-grey-light);
}