@import url('../../config/colors.css');

.HeadingWithIconContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.HeadingWithIconLeftColumn {
  width: 40px;
}

.HeadingWithIconTitles {
  display: flex;
  flex-direction: column;
}

.HeadingWithIconHeading {
  font-family: 'Nunito-Light';
  font-size: 26px;
  line-height: 36px;
  color: var(--colors-black-absolute);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
  text-align: left;
}

.HeadingWithIconSubtitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  line-height: 38px;
  color: var(--colors-black-absolute);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
  text-align: left;
}

@media (max-width: 1200px) {
  .HeadingWithIconTitles {
    flex-direction: column;
  }
}