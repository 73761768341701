@import url('../../config/colors.css');
.ButtonGreyOuterContainer {
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.ButtonGreyOuterContainerNoPadding {
  padding-left: 0;
  padding-right: 0;
}

.ButtonGreyContainer {
  width: 100%;
  height: 40px;
  margin-left: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  position: relative;
  outline: none;
  cursor: pointer;
}

.ButtonGreyLargeContainer {
  height: 48px;
  border-radius: 24px;
}

.ButtonGreySmallContainer {
  height: 30px;
  border-radius: 15px;
  width: 133px;
}

.ButtonGreyBorder {
  border-width: 1px;
  border-color: var(--colors-secondary-alternative);
}

.ButtonGreyLabel {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--colors-white-absolute);
  text-align: center;
}

.ButtonGreyLargeLabel {
  font-size: 15px;
}

.ButtonGreyIcon {
  position: absolute;
  right: 15px;
  top: 10px;
}
