@import url('../../config/colors.css');

.MatchContainer {
  width: 30%;
  position: relative;
}

.MatchButton {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.MatchAspect {
  position: relative;
  width: 100%;
}

.MatchImageContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.MatchBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MatchProfileImg {
  position: absolute;
  top: 17%;
  left: 18%;
  width: 60%;
  height: 60%;
  border-radius: 100%;
  object-fit: cover;
}

.MatchPlay {
  bottom: 45%;
  left: 50%;
  transform: translateX(-58%);
  position: absolute;
}

@media (max-width: 900px) {
  .MatchContainer {
    width: 70%;
  }
}