@import url('../../config/colors.css');

.ButtonPurple {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 30px;
  color: var(--colors-white-absolute);
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.ButtonSmallPurple {
  width: 133px;
}

.ButtonPurpleFill {
  width: 100%;
}

.ButtonLargePurple {
  width: 226px;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 48px;
}

.ButtonPurpleIcon {
  margin-left: 25px;
}

.ButtonPurple:active {
  background-color: var(--colors-secondary-alternative2);
}


