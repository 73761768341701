@import url('../../config/colors.css');

.MorePhotoCircleContainer {
  position: relative;
  width: 37%;
  height: 0;
  padding-bottom: 37%;
  margin-top: 20px;
}

.MorePhotoCircleAroundPhoto {
  width: 100%;
  height: 100%;
}

.MorePhotoCircleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  box-shadow: 0px 3px 6px var(--colors-primary-default);
}

@media (min-width: 1922px) {
  .MorePhotoCircleContainer {
    width: 35%;
    padding-bottom: 35%;
  }
}

@media (max-width: 900px) {
  .MorePhotoCircleContainer {
    width: 28%;
    padding-bottom: 28%;
  }
}
