@import url('../../config/colors.css');

.InputContainer {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: var(--colors-white-absolute);
  border-style: solid;
  border-color: var(--colors-grey-semitransparent);
  border-width: 1px;
  border-radius: 25px;
  box-shadow: 0px 3px 0px var(--colors-grey-ultratransparent);
}

.InputTextInput {
  flex: 1;
  width: 65%;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Nunito-Light';
  font-size: 14px;
  border-style: none;
  outline: none;
  color:  var(--colors-black-default);
}

.InputTextInput::placeholder {
  color: var(--colors-grey-light);
}

@media (max-width: 1600px) {
  .InputContainer {
    height: 40px;
  }
  .InputTextInput {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}