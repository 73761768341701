@import url('../../config/colors.css');

.InvitationsContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.InvitationsCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.InvitationsProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

@media (max-width: 900px) {
  .InvitationsCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .InvitationsProfileImage {
    visibility: hidden;
  }
}