.DreamEditorContainer {
  display: flex;
  flex-direction: column;
}

.DreamEditorContent {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.DreamEditorInputContainer {
  width: 100%;
  height: 100%;
  padding-top: 75px;
}