@import url('../../config/colors.css');

.ButtonWhite {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color:white;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 30px;
  color: #6A4DD1;
  text-align: center;
  border-color: #6A4DD1;
  border: solid;
  border-width: 1px;
  outline: none;
  cursor: pointer;
}

.ButtonLargeWhite {
  width: 226px;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 48px;
}

.ButtonWhite:active {
  background-color: rgb(111, 89, 190);
}
