@import url('../../config/colors.css');

.PaginationCounterContainer {
  margin-left: auto;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.PaginationCounterAmount {
  padding-top: 2px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

.PaginationCounterImage {
  margin-left: 8px;
  margin-top: 12px;
  width: 14px;
  height: 18px;
}

