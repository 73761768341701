@import url('../../config/colors.css');

.ActiveChatModalContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.ActiveChatModalBlur {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports (backdrop-filter: blur(10px)) {
  .ActiveChatModalBlur {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
  }
}

@supports not (backdrop-filter: blur(10px)) {
  .ActiveChatModalBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.ActiveChatModalContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ActiveChatModalTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  margin-bottom: 3%;
  color: var(--colors-black-absolute);
}

.ActiveChatModalProfileContent {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 322px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ActiveChatModalText {
  margin-top: 30px;
  margin-bottom: 5%;
  color: var(--colors-secondary-default);
  font-family: 'Nunito-Regular';
  font-size: 17px;
  text-align: center;
  white-space: pre-wrap;
}

.ActiveChatModalCloseButton {
  margin-top: 24px;
}