.MentorsEditorContainer {
  display: flex;
  flex-direction: column;
}

.MentorsEditorContainer > * {
  margin: 10px 0;
  margin-left: 0;
  padding-left: 0;
}

.MentorsEditorHeading {
  margin-top: 0px;
  padding-bottom: 20px;
}

.MentorsEditorHeading {
  margin-bottom: 0px;
}

.MentorsEditorAddButton {
  margin-top: 16px;
  width: 100%;
}