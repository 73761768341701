@import url('../../config/colors.css');

.PhotoCircleContainer {
  position: relative;
  width: 127px;
  height: 127px;
}

.PhotoCircleAroundPhoto {
  width: 100%;
  height: 100%;
}

.PhotoCircleDashedBorder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-color: var(--colors-primary-default);
  border-width: 1px;
  border-style: dashed;
}

.PhotoCircleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.PhotoCircleButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  background-color: var(--colors-secondary-alternative);
  border-width: 0;
  outline: none;
  cursor: pointer;
}

.PhotoCircleButtonWhite {
  background-color: var(--colors-white-absolute);
}

.PhotoCircleDream {
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.PhotoCircleButtonImage {
  /* no style for now */
  width: 14px;
  height: 14px;
}

.PhotoCircleInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  outline: none;
  opacity:0.0;
  cursor: pointer;
}

.PhotoCircleRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 0;
  top: -30px;
}

@media (max-width: 550px) {
  .PhotoCircleContainer {
    position: relative;
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 470px) {
  .PhotoCircleContainer {
    position: relative;
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 420px) {
  .PhotoCircleContainer {
    position: relative;
    width: 85px;
    height: 85px;
  }
}