@import url('../../config/colors.css');

.ChatBoxPhotoContainer {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxPhotoChatPhoto {
  height: 200px;
  max-width: 70%;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  background-color: var(--colors-grey-light);
}
