@import url('../../config/colors.css');

.QuoteContainer {
  position: relative;
  width: 100%;
  height: 340px;
  border-radius: 7px;
  overflow: hidden;
}

.QuoteBackgroiundImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 340px;
  object-fit: cover;
  filter: blur(20px);
  -webkit-filter: blur(20px);
}

.QuoteDarkOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--colors-black-transparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 7px;
}

.QuoteImage {
  align-self: center;
}

.QuoteContentTextTitle {
  font-family: 'Nunito-Regular';
  text-align: center;
  font-size: 40px;
  color: var(--colors-white-default);
}

.QuoteContentText {
  font-family: 'Nunito-Light';
  text-align: center;
  font-size: 32px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--colors-white-default);
}