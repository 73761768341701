@import url('../../config/colors.css');

.MenuContainer {
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--colors-primary-default);
  width: 123px;
  height: 100%;
  min-height: 520px;
  overflow: hidden;
  z-index: 2;
}

.MenuContainerOpen {
  width: 400px;
  align-items: flex-start;
  padding-left: 43px;
  box-sizing: border-box;
}

.MenuHeader {
  height: 62px;
  width: 100%;
  font-family: 'Nunito-Light';
  font-size: 30px;
  color: var(--colors-white-absolute);
}

.MenuHeaderOpened {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MenuTitleLine {
  position: absolute;
  top: 80px;
  left: 45px;
  min-height: 1px;
  width: 30px;
  background-color: var(--colors-secondary-alternative);
}

.MenuCloseButton {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 30px;
}

.MenuItemsContainer {
  display: flex;
  margin-top: 75px;
  flex-direction: column;
  align-items: flex-start;
  height: 360px;
  justify-content: space-between;
}

.MenuItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

#MenuItemHoverContainer {
  position: absolute;
  padding-top: 21px;
  width: 100px;
  text-align: center;
  visibility: hidden;
  color: var(--colors-white-absolute);
}

.MenuItemHoverSelected {
  color: var(--colors-secondary-alternative) !important;
}

.MenuItemHoverContainerFix {
  padding-top: 11px !important;
}

.MenuItemsContainer :hover #MenuItemHoverContainer{
  visibility: visible;
}


.MenuItemBadge {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: var(--colors-tertiary-alternative2);
  position: absolute;
  left: 70px;
}

.MenuItemBadgeNumber {
  margin: 0;
  line-height: 16px;
  text-align: center;
  font-family: 'Nunito-Regular';
  font-size: 10px;
  color: var(--colors-white-absolute);
}

.MenuIcon {
  margin-top: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.MenuText {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-left: 35px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colors-white-absolute);
}

.MenuTextSelected {
  color: var(--colors-secondary-alternative);
}

.MenuLogoutContainer {
  position: absolute;
  bottom: 50px;
  left: 46px;
  width: 30px;
  height: 30px;
}

.MenuLogoutIcon {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 0;
}

.MenuLogoutContainer :hover ~ .MenuLogoutHoverContainer{
  visibility: visible;
}

.MenuLogoutHoverContainer {
  padding-top: 16px;
  margin-left: -16px;
  width: 100px;
  text-align: left;
  visibility: hidden;
  color: var(--colors-white-absolute);
}

.MenuLogoutText {
  position: absolute;
  font-family: 'Nunito-Regular';
  font-size: 20px;
  color: var(--colors-white-absolute);
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  bottom: 50px;
  left: 110px;
}

@media (max-width: 900px) {
  .MenuContainer {
    width: 60px;
    height: 78px;
    min-height: 78px;
    position: absolute;
  }
  .MenuContainerOpen {
    height: 100%;
    width: 260px;
  }
  .MenuPage {
    margin-left: 0px;
  }
  .MenuIcon {
    margin-right: 0;
  }
  .MenuLogoutIcon {
    visibility: hidden;
  }

  .MenuLogoutIconVisible {
    visibility: visible;
  }
}

@media (max-height: 600px) {
  .MenuItemsContainer {
    height: 288px;
  }

  #MenuItemHoverContainer {
    padding-top: 16px;
  }

  .MenuItemHoverContainerFix {
    padding-top: 8px !important;
  }
}