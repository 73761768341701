@import url('../../config/colors.css');

.AboutContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.AboutBackground {
  background-image: url('../../assets/images/background-login.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1180px;
  width: 100%;
  height: 120%;
  position: absolute;
  z-index: -1;
}

.AboutFormContainer {
  min-width: 320px;
  margin-top: -60px;
}

.AboutText {
  white-space: pre-wrap;
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 20px;
  max-width: 320px;
  text-align: justify;
  color: var(--colors-black-default);
  margin-bottom: 20px;
}