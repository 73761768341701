@import url('../../config/colors.css');

.FilterContainer {
  margin-left: 8%;
  margin-top: 40px;
  margin-right: 178px;
  max-width: 1460px;
}

.FilterRow {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 0px;
}

.FilterBox {
  min-width: 470px;
  min-height: 280px;
  width: 470px;
  height: 280px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.FilterValueContainer {
  margin-top: 64px;
}

.FilterValueText {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-default);
}

.FilterSlider {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
}

.FilterSaveButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.FilterGenderContainer {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .FilterContainer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FilterRow {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 660px) {
  .FilterBox {
    min-width: 320px;
    width: 80%;
  }
}