@import url('../../config/colors.css');

.SelectInputContainer {
  width: 100%;
  position: relative;
}

.SelectInputInnerContainer {
  /* width: 100%; */
  height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid var(--colors-primary-default);
  border-radius: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.SelectInputSelector {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  height: 40px;
  border-color: transparent;
  font-family: 'Nunito-Regular';
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  text-align-last: center;
  outline: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.SelectInputTriangle {
	width: 0;
  height: 0;
  position: absolute;
  right: 25px;
  top: 15px;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
  border-top: 10px solid var(--colors-secondary-alternative);
  pointer-events: none;
}

.SelectInputPlaceholder {
  color: var(--colors-grey-light);
}

.SelectInputDeleteButton {
  position: absolute;
  right: 50px;
  top: 10px;
  outline: none;
  background-color: transparent;
  border: none;
}

.SelectInputDeleteIcon {
  width: 15px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
}

.SelectInputRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 24px;
  top: -26px;
}

@media (max-width: 520px) {
  .SelectInputSelector {
    text-indent: 0 !important;
  }
}