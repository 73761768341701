:root {
  --colors-black-default: #030303;
  --colors-black-absolute: #000000;
  --colors-black-transparent: rgba(0,0,0,0.5);
  --colors-black-ultratransparent: rgba(0,0,0,0.16);

  --colors-white-default: #F6FBF8;
  --colors-white-absolute: #FFFFFF;
  --colors-white-transparent: rgba(255, 255, 255, 0.9);
  --colors-white-ultratransparent: rgba(255, 255, 255, 0.5);

  --colors-grey-ultralight: #F3F8FD;
  --colors-grey-lighter: #EEEEEE;
  --colors-grey-light: #CCCCCC;
  --colors-grey-medium: #979797;
  --colors-grey-dark: #707070;
  --colors-grey-text: #929292;
  --colors-grey-semitransparent: rgba(151, 151, 151, 0.22);
  --colors-grey-ultratransparent: rgba(0,0,0,0.07);

  --colors-primary-default: #0FD2AD;
  --colors-primary-alternative: #50E3C2;

  --colors-secondary-default: #261B4B;
  --colors-secondary-alternative: #6A4DD1;
  --colors-secondary-alternative2: rgb(111, 89, 190);
  --colors-secondary-alternative3: rgb(127, 109, 196);
  --colors-secondaty-alternative4: #9013FE;

  --colors-tertiary-default: #F701F1;
  --colors-tertiary-alternative: #FB0C57;
  --colors-tertiary-alternative2: #F90B5E;

  --colors-level-1: #F701F1;
  --colors-level-2: #F506B4;
  --colors-level-3: #F40C7A;
  --colors-level-4: #F31144;
  --colors-level-5: #F11C17;
  --colors-level-6: #F0561C;
  --colors-level-7: #EF8D22;
  --colors-level-8: #EDC127;
  --colors-level-9: #E7EC2C;
  --colors-level-10: #B8EB32;
  --colors-level-11: #8BE937;
  --colors-level-12: #62E83C;
  --colors-level-13: #41E645;
  --colors-level-14: #46E572;
  --colors-level-15: #4BE49B;
  --colors-level-16: #50E3C2;

  --colors-status-selected: #15D76D;
  --colors-status-success: #0FD2AD;
  --colors-status-danger: #FB0C57;
  --colors-status-warning: #6A4DD1;
  --colors-status-info: #0E75FB;
  --colors-status-available: #15D76D;
  --colors-status-unavailable: #FB0C57;
  --colors-status-active: #6A4DD1;
  --colors-status-pending: #CCCCCC;
  --colors-status-newMessage: #15D76D;
  --colors-status-closed: #CCCCCC;
}
