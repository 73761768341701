@import url('../../config/colors.css');

.LevelExplanationContainer {
  background-color: var(--colors-white-absolute);
  margin-top: 45px;
  margin-left: 250px;
  margin-right: 150px;
  margin-bottom: 45px;
}

.LevelExplanationTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  line-height: 22px;
  color: var(--colors-black-default);
}

.LevelExplanationText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 22px;
  color: var(--colors-black-default);
}

@media (max-width: 900px) {
  .LevelExplanationContainer {
    margin-top: 80px;
    margin-left: 50px;
    margin-right: 50px;
  }
}