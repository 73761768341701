@import url('../../config/colors.css');

.InvitationCardButton {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.InvitationCardContainer {
  height: 80px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.InvitationCardProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 34px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}

.InvitationCardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.InvitationCardContentRow {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.InvitationCardStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InvitationCardUserInfo {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-right: 20px;
  white-space: nowrap;
  color: var(--colors-black-default);
}

.InvitationCardDate {
  font-family: 'Nunito-Regular';
  font-size: 12px;
  color: var(--colors-grey-light);
}

.InvitationCardCircle {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 5px;
}

.InvitationCardStatus {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

@media (max-width: 1100px) {
  .InvitationCardProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }

  .InvitationCardContentRow {
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .InvitationCardUserInfo {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .InvitationCardContentRow {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 400px) {
  .InvitationCardUserInfo {
    font-size: 14px;
  }
}

