@import url('../../config/colors.css');

.PaginationContainer {
  padding-top: 17px;
  flex-direction: row;
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  height: 67px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.PaginationNumbersContainer {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media (max-width: 800px) {
  .PaginationContainer {
    width: 100%;
    transform: translateX(0%);
  }
}