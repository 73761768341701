@import url('../../config/colors.css');

.AdmissionQuestionContainer {
  background-color: var(--colors-white-default);
  min-height: 100vh;
}

.AdmissionQuestionBody {
  display: flex;
  flex-direction: row;
  width: 70%;
  transform: translateX(-5%);
  margin-left: auto;
  margin-right: auto;
}

.AdmissionQuestionAnswersContainer {
  background-color: var(--colors-white-absolute);
  width: 50%;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: 15px;
  margin-top: 20px;
}

.AdmissionQuestionButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 95%;
  height: 100%;
  padding-top: 24px;
}

.AdmissionQuestionCorner {
  position: absolute;
  width: 360px;
  right: 0px;
  z-index: 1;
}

@media (max-width: 800px) {
  .AdmissionQuestionAnswersContainer {
    width: 100%;
    transform: translateX(0%);
    margin-left: 0;
  }

  .AdmissionQuestionCorner {
    visibility: hidden;
  }
  .AdmissionQuestionBody {
    flex-direction: column;
    justify-content: center;
    transform: translateX(0);
    width: 90%;
  }
}
