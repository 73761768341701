@import url('../../config/colors.css');

.SignUpSentence {
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-black-default);
  text-align: center;
}

.SignUpError {
  color: var(--colors-status-danger);
}

.LoginFormPasswordInput {
  margin-top: 20px;
}

.LoginFormButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: block;
}

.SignUpCheckboxContainer {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.SignUpFormText {
  font-size: 12px;
  line-height: 12px;
  font-family: 'Nunito-Regular';
  color: var(--colors-secondary-alternative);
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 5px;
}

.SignUpFormTextUnderline {
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.SignUpFormInputContainer {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: var(--colors-white-absolute);
  border-style: solid;
  border-color: var(--colors-grey-semitransparent);
  border-width: 1px;
  border-radius: 25px;
  box-shadow: 0px 3px 0px var(--colors-grey-ultratransparent);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SignUpFormDateContainer {
  padding-left: 52px;
  width: 100%;
  display: flex;
}

.SignUpFormDatePicker {
  display: flex;
  border: none;
  font-family: 'Nunito-Regular';
  width: 220px;
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: left;
  outline: none;
}

.SignUpFormDatePicker::placeholder {
  color: var(--colors-grey-light);
}

.SignUpFormDateIcon {
  position: absolute;
  right: 27px;
  top: 14px;
}

.SignUpFormTermContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 2px;
}

@media (max-width: 1600px) {
  .SignUpFormInputContainer {
    height: 40px;
  }
  .SignUpFormDateIcon {
    top: 9px;
  }
}


