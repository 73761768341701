@import url('../../config/colors.css');

.InspiringPeopleListItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
}

.InspiringPeopleListItemAroundIcon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-primary-default);
}

.InspiringPeopleListItemName {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 14px;
  font-family: 'Nunito-Light';
  font-size: 18px;
  color: var(--colors-secondary-alternative);
}

