.SignupContainer {
  min-height: 830px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignupBackground {
  background-image: url('../../assets/images/background-signup.svg');
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: 50% 45%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.SignupFormContainer {
  margin-top: 0px;
  min-width: 320px;
  margin-top: -40px;
}

@media (max-width: 1560px) {
  .SignupBackground {
    background-size: 75%;
    background-position: 50% 40%;
  }
}

@media (max-width: 1490px) {
  .SignupBackground {
    background-size: 78%;
  }
}

@media (max-width: 1410px) {
  .SignupBackground {
    background-size: 80%;
    height: 110%;
  }
}

@media (max-width: 1270px) {
  .SignupBackground {
    background-size: 1080px;
    height: 150%;
  }
  .SignupFormContainer {
    margin-top: -110px;
  }
}

@media (max-width: 410px) {
  .SignupFormContainer {
    margin-top: -60px;
  }
}
