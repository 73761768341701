@import url('../../config/colors.css');

.TabSingleButton {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.TabSingleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TabSingleTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  color: var(--colors-black-absolute);
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.TabSingleTitleActive {
  font-family: 'Nunito-Light';
  color: var(--colors-secondary-alternative);
}

.TabSingleVerticalBar {
  width: 1px;
  height: 21px;
  background-color: var(--colors-primary-default);
}

.TabSingleVerticalLeft {
  margin-right: 10px;
}

.TabSingleVerticalRight {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .TabSingleTitle {
    margin-left: 5px;
    margin-right: 5px;
  }
}