@import url('../../config/colors.css');

.MatchesListList {
  margin-left: 20px;
  margin-top: 20px;
  max-width: 1204px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.MatchesListContentContainer {
  margin-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.MatchesListButtonContainer {
  margin-left: 20px;
  margin-top: 20px;
  max-width: 65%;
  min-height: 200px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MatchesText {
  margin-left: 6%;
  padding-top: 34px;
  font-family: 'Nunito-Regular';
  font-size: 16px;
  text-align: left;
}

@media (max-width: 900px) {
  .MatchesListList {
    margin-left: 0;
  }

  .MatchesListButtonContainer {
    margin-left: 0;
    max-width: 100%;
  }

  .MatchesListContentContainer {
    flex-direction: column;
    align-items: center;
  }
}