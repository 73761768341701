@import url('../../config/colors.css');

.FilterHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 67px;
  width: 100%;
  max-width: 1450px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.FilterHeaderText {
  font-family: 'Nunito-Light';
  font-size: 30px;
  text-align: left;
  padding-left: 25px;
}


@media (max-width: 900px) {
  .TitleBar {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
}