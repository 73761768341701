@import url('../../config/colors.css');

.AnswerContainer {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  padding-top: 15px;
}

.AnswerImage {
  margin-right: 20px;
  width: 150px;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
}

.AnswerAroundText {
  display: inline-flex;
  min-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 14px;
  padding-right: 14px;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 2px 2px 1px var(--colors-black-ultratransparent);
}

.AnswerSelected {
  border: solid;
  border-width: 3px;
  border-color: var(--colors-status-selected)
}

.AnswerIcon {
  margin-right: 12;
  margin-top: 4px;
}

.AnswerText {
  display: flex;
  padding-left: 10px;
  padding-top: 6px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
  text-align: left;
}

.AnswerSelectedText {
  color: var(--colors-status-selected);
}