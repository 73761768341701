.InvitationsListContainer {
  width: 65%;
  margin-left: 8%;
}

.InvitationsListTabs {
  margin-bottom: 16px;
}

@media (max-width: 900px) {
  .InvitationsListContainer {
    width: 100%;
    margin-left: 0;
  }
}