@import url('../../config/colors.css');

.LoginFormPasswordInput {
  margin-top: 20px;
}

.LoginFormButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: block;
}

.LoginFormError {
  max-width: 320px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  padding-top: 0;
  padding-bottom: 10px;
  color: var(--colors-status-danger);
  text-align: center;
}