@import url('../../config/colors.css');

.LevelBarButton {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  left: 10px;
  top: 155px;
}

.LevelBarContainer {
  min-width: 138px;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
}

.LevelBarText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 35px;
  color: var(--colors-white-absolute);
}

.LevelBarProfile {
  left: 0px;
  top: 120px;
}

.LevelBarContainerProfile {
  min-width: 100px;
}