.Title {
  font-family: 'Nunito-Light';
  font-size: 35px;
  text-align: center;
  margin-top: 0px;
}

@media (max-width: 1560px) {
  .Title {
    font-size: 29px;
  }
}