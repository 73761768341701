@import url('../../config/colors.css');

.VoiceMessageRecorderButtonContainer {
  margin-top: 70px;
}

.VoiceMessageRecorderAnimation {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
}

.VoiceMessageRecorderPreview {
  width: 100%;
  margin-top: 40px;
}

.VoiceMessageRecorderText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  color: var(--colors-black-default);
}