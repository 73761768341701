.ProfileWithLevelContainer {
  position: relative;
  width: 150px;
  height: 150px;
}

.ProfileWithLevelProfile {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 140px;
  height: 140px;
  border-radius: 86px;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}