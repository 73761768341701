@import url('../../config/colors.css');

.ChatBoxTextContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxTextMessageContainer {
  max-width: 80%;
  padding-top: 12px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  box-sizing: border-box;
}

.ChatBoxTextMessageContainerPartner {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatBoxTextText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.ChatBoxTextDateText {
  color: var(--colors-grey-light);
  font-family: 'Nunito-Regular';
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: 5px;
}

@media (max-width: 900px) {
  .ChatBoxTextContainer {
    padding-right: 10px;
  }
}
