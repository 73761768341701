@import url('../../config/colors.css');

.TitleChat {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 88px;
  width: 65%;
  margin-left: 8%;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  position: relative;
}

.TitleChatProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 30px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

.TitleChatName {
  font-family: 'Nunito-Regular';
  font-size: 20px;
}

.TitleChatStopButton {
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.TitleChatStopText {
  font-family: 'Nunito-Light';
  font-size: 14px;
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .TitleChatProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .TitleChat {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
  .TitleChatName {
    font-size: 17px;
  }
}

@media (max-width: 400px) {
  .TitleChatName {
    font-size: 14px;
  }
}