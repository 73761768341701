.QuestionImageContainer {
  width: 90%;
  height: 180px;
  overflow: hidden;
  padding-bottom: 20px;
}

.QuestionImage {
  flex: 1;
  max-width: 100%;
  max-height: 100%;
}