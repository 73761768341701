@import url('../../config/colors.css');

.ButtonCirclePurpleButton {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ButtonCirclePurpleButton :hover + #ButtonCirclePurpleAlt {
  color: var(--colors-black-default);
}

.ButtonCirclePurpleContainer {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
}

.ButtonCirclePurpleMedium {
  width: 60px;
  height: 60px;;
}

.ButtonCirclePurpleIcon {
  width: 40px;
  height: 40px;
}

.ButtonCirclePurpleIconMedium {
  width: 30px;
  height: 30px;
}

.ButtonCirclePurpleIconSmall {
  width: 20px;
  height: 20px;
}

.ButtonCirclePurpleAltText {
  position: absolute;
  color: transparent;
  white-space: pre-wrap;
  font-family: 'Nunito-Regular';
  font-size: 12px;
  min-width: 120px;
  max-width: 120px;
  left: -20px;
}