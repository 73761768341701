@import url('../../config/colors.css');

.ChatBoxVoiceContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxVoiceWrapper {
  padding-top: 12px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  box-sizing: border-box;
}

.ChatBoxVoiceWrapperPartner {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatBoxVoiceVoiceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ChatBoxVoiceWaveform {
  height: 50px;
  max-width: 80%;
  object-fit: cover;
}

.ChatBoxVoiceTextDate {
  color: var(--colors-grey-light);
  font-family: 'Nunito-Regular';
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: 5px;
}

@media (max-width: 400px) {
  .ChatBoxVoiceWaveform {
    height: 30px;
  }
}