@import url('../../config/colors.css');

.ChatInputContainer {
  width: 65%;
  margin-left: 8%;
  height: 48px;
  border-radius: 16px;
  background-color: var(--colors-white-absolute);
  display: flex;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatInputInputWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ChatInputInput {
  display: flex;
  font-family: 'Nunito-Regular';
  font-size: 15px;
  margin-left: 25px;
  margin-right: 10px;
  color: var(--colors-grey-medium);
  width: 100%;
  border: none;
  box-sizing: border-box;
  outline: none;
}

.ChatInputInput::placeholder {
  color: var(--colors-grey-light);
}

.ChatInputButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.ChatInputLastButton {
  margin-right: 20px;
}

.ChatInputInputIcon {
  object-fit: contain;
  height: 20px;
  width: 25px;
}

.ChatInputRecorderAnimation {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
}

@media (max-width: 900px) {
  .ChatInputContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
}