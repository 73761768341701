@import url('../../config/colors.css');

.QuestionContainer {
  margin-top: 20px;
  background-color: var(--colors-white-absolute);
  width: 50%;
  min-height: 80px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.QuestionText {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Nunito-Regular';
  font-size: 25px;
  line-height: 30px;
  color: var(--colors-black-default);
  text-align: left;
  width: 90%;
}

@media (max-width: 800px) {
  .QuestionContainer {
    width: 100%;
    transform: translateX(0%);
  }
}
