@import url('../../config/colors.css');

.ChatListContainer {
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  flex-grow : 1;
  margin-top: 20px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

@media (max-width: 900px) {
  .ChatListContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
}