@import url('../../config/colors.css');

.SoundBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SoundBarImage {
  height: 120px;
  width: 60%;
  margin-left: 0px;
  margin-right: 20px;
}

.SoundBarLength {
  font-family: 'Nunito-Light';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
}