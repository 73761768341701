@import url('../../config/colors.css');

.GoPremiumModalContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.GoPremiumModalBlur {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports (backdrop-filter: blur(10px)) {
  .GoPremiumModalBlur {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
  }
}

@supports not (backdrop-filter: blur(10px)) {
  .GoPremiumModalBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.GoPremiumModalContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GoPremiumModalBox {
  width: 514px;
  height: 540px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 10px;
}

.GoPremiumModalBoxPayment {
  height: 450px;
}

.GoPremiumForm {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.GoPremiumButtons {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.GoPremiumButtons > div
{
  margin-left: 25px;
}

.GoPremiumModalTitle {
  font-family: 'Nunito-Bold';
  font-size: 24px;
  color: var(--colors-secondary-alternative);
}

.GoPremiumModalInputTitle {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-absolute);
  margin-bottom: 5px;
}

.GoPremiumModalCheckout {
  padding-top: 40px;
}

.GoPremiumModalComplete {
  padding-top: 80px;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 12px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media (max-width: 520px) {
  .GoPremiumModalBox {
    width: 95%;
  }
}