@import url('../../config/colors.css');

.ChatHistoryCardButton {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.ChatHistoryCardContainer {
  height: 80px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatHistoryCardProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 30px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}

.ChatHistoryCardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ChatHistoryCardContentRow {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.ChatHistoryCardUserInfo {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-right: 20px;
  white-space: nowrap;
  color: var(--colors-black-default);
}

.ChatHistoryCardDate {
  font-family: 'Nunito-Regular';
  font-size: 12px;
  color: var(--colors-grey-light);
}

.ChatHistoryCardStatus {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

@media (max-width: 1100px) {
  .ChatHistoryCardProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }

  .ChatHistoryCardContentRow {
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .ChatHistoryCardUserInfo {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .ChatHistoryCardContentRow {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 400px) {
  .ChatHistoryCardUserInfo {
    font-size: 14px;
  }
}
