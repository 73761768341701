@import url('../../config/colors.css');

.SubheadingContainer {
  display: flex;
  flex-direction: row;
}

.SubheadingTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  text-align: left;
  padding-left: 28px;
  margin-bottom: -10px;
  color: var(--colors-black-absolute);
}

.SubheadingTitleCenter {
  justify-content: center;
  text-align: center;
  padding-left: 0;
}

.SubheadingTitleLarge {
  font-size: 30px;
}

.SubheadingTitleRequired {
  margin-left: 3px;
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
}