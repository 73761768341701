.LevelCirclesContainer {
  position: relative;
  top: 70px;
  left: 150px;
  width: 210px;
  height: 210px;
}

.LevelCirclesContainerNoBar {
  position: relative;
  width: 210px;
  height: 210px;
}

.LevelCirclesBackground {
  position: relative;
  top: 0px;
  left: 0px;
  width: 210px;
  height: 210px;
}

.LevelCirclesProfile {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  overflow: hidden;
  object-fit: cover;
}

@media (max-width: 900px) {
  .LevelCirclesContainer {
    position: relative; left: 50%;
    transform: translateX(-50%);
  }
}