@import url('../../config/colors.css');

.ObverlayMatchContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.OverlayMatchBlur {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports (backdrop-filter: blur(10px)) {
  .OverlayMatchBlur {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
  }
}

@supports not (backdrop-filter: blur(10px)) {
  .OverlayMatchBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.ObverlayMatchContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ObverlayMatchTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  margin-bottom: 3%;
  color: var(--colors-black-absolute);
}

.ObverlayMatchCircles {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 322px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/your-a-match-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400px;
}

.ObverlayMatchPerson {
  position: absolute;
  max-height: 160px;
  max-width: 160px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  top: 75px;
}

.ObverlayMatchFirstPerson {
  position:relative;
  left: 15px;
  top: -6px;
}

.ObverlayMatchMatchPerson {
  position:relative;
  left: -5px;
  top: -6px;
}

.ObverlayMatchMatchText {
  margin-top: 0;
  margin-bottom: 5%;
  color: var(--colors-secondary-default);
  font-family: 'Nunito-Regular';
  font-size: 17px;
  text-align: center;
  white-space: pre-wrap;
}

.ObverlayMatchLaterButton {
  margin-top: 24px;
}