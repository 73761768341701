.ProfileMorePhotosContainer{
  width: 100%;
  height: 100%;
}

.ProfileMorePhotosListContainer {
  margin-top: 20px;
  margin-left: 6%;
  width: 90%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}