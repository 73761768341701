@import url('../../config/colors.css');

.SettingsContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.SettingsCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.SettingsContent {
  margin-top: 20px;
  min-height: 630px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.SettingsButtonsContainer {
  padding-top: 62px;
  margin-left: 6%;
  margin-bottom: 50px;
  width: 90%;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.SettingsButtonLeft {
  margin-right: 15px;
  width: 100%;
}

.SettingsButtonRight {
  margin-left: 15px;
  width: 100%;
}

.SettingsAroundQuote {
  width: 90%;
  max-width: 500px;
  margin-left: 6%;
}

.SettingsButtonLogout {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 240px;
  margin-left: 6%;
}

@media (max-width: 900px) {
  .SettingsContent {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .SettingsCorner {
    visibility: hidden;
  }
  .SettingsButtonsContainer {
    margin-left: auto;
    margin-right: auto;
  }
  .SettingsAroundQuote {
    margin-left: auto;
    margin-right: auto;
  }
  .SettingsButtonLogout {
    margin-left: auto;
    margin-right: auto;
  }
}