@import url('../../config/colors.css');

.GenderSelectionContainer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 90px;
}

.GenderSelectionButton {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.GenderSelectionText {
  font-family: 'Nunito-Light';
  font-size: 14px;
  color: var(--colors-black-default);
  margin-top: 5px;
  margin-bottom: 5px;
}

.GenderSelectionImage {
  margin-bottom: 0px;
  height: 40px;
}

@media (max-width: 1560px) {
  .GenderSelectionContainer {
    height: 65px;
  }
  .GenderSelectionImage {
    height: 30px;
  }
}