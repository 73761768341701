.LostPasswordContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.LostPasswordBackground {
  background-image: url('../../assets/images/background-login.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1080px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.LostPasswordFormContainer {
  min-width: 320px;
}

.LostPasswordButtons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LostPasswordButtons button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.LostPasswordError {
  max-width: 320px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  padding-top: 0;
  padding-bottom: 10px;
  color: var(--colors-status-danger);
  text-align: center;
}