@import url('../../config/colors.css');

.DreamCircleContainer {
  width: 100%;
  height: 204px;
  display: flex;
  justify-content: center;
}

.DreamCircleAroundPhoto {
  max-width: 234px;
  max-height: 234px;
  width: 48%;
  height: 0;
  padding-bottom: 48%;
  position: relative;
}

.DreamCircleDashedBorder {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-color: var(--colors-primary-default);
  border-width: 1px;
  border-style: dashed;
  background-color: var(--colors-white-ultratransparent);
}

.DreamCircleImage {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 99%;
  height: 99%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.DreamCircleText {
  font-family: 'Nunito-Bold';
  color: var(--colors-secondary-default);
  position: absolute;
  width: 72%;
  height: 72%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

@media (min-width: 1922px) {
  .DreamCircleAroundPhoto {
    width: 45%;
    padding-bottom: 45%;
  }
}


@media (max-width: 900px) {
  .DreamCircleContainer {
    height: 254px;
  }
}
