@import url('../../config/colors.css');

.SubscriptionCard {
  width: 300px;
  height: 390px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 10px;
}

.SubscriptionCardActive {
  border:1px solid var(--colors-primary-alternative)
}

.SubscriptionCardSeparator {
  height: 1px;
  margin-left: 21px;
  margin-right: 21px;
  background-color: var(--colors-secondaty-alternative4);
  margin-bottom: 42px;
}

.SubscriptionCardTitle {
  font-family: 'Nunito-Bold';
  font-size: 20px;
  margin-top: 21px;
  margin-bottom: 23px;
}

.SubscriptionCardPrice {
  font-family: 'Nunito-Bold';
  font-size: 30px;
  color: var(--colors-primary-alternative);
  margin-top: 0;
  margin-bottom: 3px;
}

.SubscriptionCardInterval {
  font-family: 'Nunito-Bold';
  font-size: 16px;
  color: var(--colors-primary-alternative);
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.SubscriptionCardDescription {
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: var(--colors-secondary-default);
  margin-top: 0;
  margin-bottom: 16px;
}

.SubscriptionCardStatus {
  margin-top: 28px;
}

.SubscriptionCardStatusDescription {
  font-family: 'Nunito-Bold';
  font-size: 17px;
  color: var(--colors-primary-alternative);
  text-transform: uppercase;
  padding-top: 30px;
}