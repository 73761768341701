@font-face {
  font-family: 'Nunito-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Light'),
       url(/static/media/Nunito-Light.c41c2502.ttf) format('truetype')

}

@font-face {
  font-family: 'Nunito-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Bold'),
       url(/static/media/Nunito-Bold.6f47bcfc.ttf) format('truetype')

}

@font-face {
  font-family: 'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Regular'),
       url(/static/media/Nunito-Regular.65bb0a15.ttf) format('truetype')

}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MenuPage {
  margin-left: 123px;
}

.notification-toast {
  border-radius: 5px;
  background-color: var(--colors-primary-default);
  color: white;
  padding-left: 20px;
}

.Toastify__toast--error {
  background: #e74c3c !important;
}
.LoginContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.LoginBackground {
  background-image: url(/static/media/background-login.1eaa3d5d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1080px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.LoginFormContainer {
  min-width: 320px;
}

.Title {
  font-family: 'Nunito-Light';
  font-size: 35px;
  text-align: center;
  margin-top: 0px;
}

@media (max-width: 1560px) {
  .Title {
    font-size: 29px;
  }
}
:root {
  --colors-black-default: #030303;
  --colors-black-absolute: #000000;
  --colors-black-transparent: rgba(0,0,0,0.5);
  --colors-black-ultratransparent: rgba(0,0,0,0.16);

  --colors-white-default: #F6FBF8;
  --colors-white-absolute: #FFFFFF;
  --colors-white-transparent: rgba(255, 255, 255, 0.9);
  --colors-white-ultratransparent: rgba(255, 255, 255, 0.5);

  --colors-grey-ultralight: #F3F8FD;
  --colors-grey-lighter: #EEEEEE;
  --colors-grey-light: #CCCCCC;
  --colors-grey-medium: #979797;
  --colors-grey-dark: #707070;
  --colors-grey-text: #929292;
  --colors-grey-semitransparent: rgba(151, 151, 151, 0.22);
  --colors-grey-ultratransparent: rgba(0,0,0,0.07);

  --colors-primary-default: #0FD2AD;
  --colors-primary-alternative: #50E3C2;

  --colors-secondary-default: #261B4B;
  --colors-secondary-alternative: #6A4DD1;
  --colors-secondary-alternative2: rgb(111, 89, 190);
  --colors-secondary-alternative3: rgb(127, 109, 196);
  --colors-secondaty-alternative4: #9013FE;

  --colors-tertiary-default: #F701F1;
  --colors-tertiary-alternative: #FB0C57;
  --colors-tertiary-alternative2: #F90B5E;

  --colors-level-1: #F701F1;
  --colors-level-2: #F506B4;
  --colors-level-3: #F40C7A;
  --colors-level-4: #F31144;
  --colors-level-5: #F11C17;
  --colors-level-6: #F0561C;
  --colors-level-7: #EF8D22;
  --colors-level-8: #EDC127;
  --colors-level-9: #E7EC2C;
  --colors-level-10: #B8EB32;
  --colors-level-11: #8BE937;
  --colors-level-12: #62E83C;
  --colors-level-13: #41E645;
  --colors-level-14: #46E572;
  --colors-level-15: #4BE49B;
  --colors-level-16: #50E3C2;

  --colors-status-selected: #15D76D;
  --colors-status-success: #0FD2AD;
  --colors-status-danger: #FB0C57;
  --colors-status-warning: #6A4DD1;
  --colors-status-info: #0E75FB;
  --colors-status-available: #15D76D;
  --colors-status-unavailable: #FB0C57;
  --colors-status-active: #6A4DD1;
  --colors-status-pending: #CCCCCC;
  --colors-status-newMessage: #15D76D;
  --colors-status-closed: #CCCCCC;
}

.InputContainer {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: var(--colors-white-absolute);
  border-style: solid;
  border-color: var(--colors-grey-semitransparent);
  border-width: 1px;
  border-radius: 25px;
  box-shadow: 0px 3px 0px var(--colors-grey-ultratransparent);
}

.InputTextInput {
  flex: 1 1;
  width: 65%;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Nunito-Light';
  font-size: 14px;
  border-style: none;
  outline: none;
  color:  var(--colors-black-default);
}

.InputTextInput::-webkit-input-placeholder {
  color: var(--colors-grey-light);
}

.InputTextInput::placeholder {
  color: var(--colors-grey-light);
}

@media (max-width: 1600px) {
  .InputContainer {
    height: 40px;
  }
  .InputTextInput {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
.ButtonPurple {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 30px;
  color: var(--colors-white-absolute);
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.ButtonSmallPurple {
  width: 133px;
}

.ButtonPurpleFill {
  width: 100%;
}

.ButtonLargePurple {
  width: 226px;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 48px;
}

.ButtonPurpleIcon {
  margin-left: 25px;
}

.ButtonPurple:active {
  background-color: var(--colors-secondary-alternative2);
}



.ButtonText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ButtonText:active {
  color: var(--colors-secondary-alternative3);
}
.LoginFormPasswordInput {
  margin-top: 20px;
}

.LoginFormButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: block;
}

.LoginFormError {
  max-width: 320px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  padding-top: 0;
  padding-bottom: 10px;
  color: var(--colors-status-danger);
  text-align: center;
}
.FooterPreAuthText {
  font-family: 'Nunito-Regular';
  font-size: 12px;
  color: var(--colors-secondary-alternative);
  margin-top: 15px;
  margin-bottom: 0px;
}
.SpinnerContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Spinner {
  max-width: 50%;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 26px;
  padding-bottom: 26px;
  color: var(--colors-black-absolute);
  background-color: var(--colors-white-default);
  box-shadow: 0px 3px 6px var(--colors-grey-dark);
  border-radius: 10px;
}

.SpinnerText {
  font-family: 'Nunito-Bold';
  font-size: 14px;
  margin: 0;
}

.LostPasswordContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.LostPasswordBackground {
  background-image: url(/static/media/background-login.1eaa3d5d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1080px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.LostPasswordFormContainer {
  min-width: 320px;
}

.LostPasswordButtons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LostPasswordButtons button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.LostPasswordError {
  max-width: 320px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  padding-top: 0;
  padding-bottom: 10px;
  color: var(--colors-status-danger);
  text-align: center;
}
.AlertContainer {
  width: 400px;
  background-color: var(--colors-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.AlertTitle {
  font-family: 'Nunito-Bold';
  font-size: 24px;
  color: var(--colors-secondary-alternative);
}

.AlertText {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  margin-left: 50px;
  margin-right: 50px;
}

.AlertButton {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-white-absolute);
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 25px;
  min-width: 133px;
  background: var(--colors-secondary-alternative);
  border: none;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
}

.AlertButtonCancel {
  background: var(--colors-grey-light);
}
.SignupContainer {
  min-height: 830px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignupBackground {
  background-image: url(/static/media/background-signup.52e42757.svg);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: 50% 45%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.SignupFormContainer {
  margin-top: 0px;
  min-width: 320px;
  margin-top: -40px;
}

@media (max-width: 1560px) {
  .SignupBackground {
    background-size: 75%;
    background-position: 50% 40%;
  }
}

@media (max-width: 1490px) {
  .SignupBackground {
    background-size: 78%;
  }
}

@media (max-width: 1410px) {
  .SignupBackground {
    background-size: 80%;
    height: 110%;
  }
}

@media (max-width: 1270px) {
  .SignupBackground {
    background-size: 1080px;
    height: 150%;
  }
  .SignupFormContainer {
    margin-top: -110px;
  }
}

@media (max-width: 410px) {
  .SignupFormContainer {
    margin-top: -60px;
  }
}

.GenderSelectionContainer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 90px;
}

.GenderSelectionButton {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.GenderSelectionText {
  font-family: 'Nunito-Light';
  font-size: 14px;
  color: var(--colors-black-default);
  margin-top: 5px;
  margin-bottom: 5px;
}

.GenderSelectionImage {
  margin-bottom: 0px;
  height: 40px;
}

@media (max-width: 1560px) {
  .GenderSelectionContainer {
    height: 65px;
  }
  .GenderSelectionImage {
    height: 30px;
  }
}
.CheckboxContainer {
  margin: -10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.CheckboxAroundState {
  width: 10px;
  height: 10px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-white-absolute);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--colors-grey-light);
  cursor: pointer;
}

.CheckboxState {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.CheckboxActive {
  background-color: var(--colors-primary-default);
}

.CheckboxText {
  font-size: 16px;
  margin-left: 14px;
  text-align: left;
  color: var(--colors-black-default);
  font-family: 'Nunito-Regular';
}

@media (max-width: 1560px) {
  .CheckboxAroundState {
    width: 6px;
    height: 6px;
  }
}
.SignUpSentence {
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-black-default);
  text-align: center;
}

.SignUpError {
  color: var(--colors-status-danger);
}

.LoginFormPasswordInput {
  margin-top: 20px;
}

.LoginFormButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: block;
}

.SignUpCheckboxContainer {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.SignUpFormText {
  font-size: 12px;
  line-height: 12px;
  font-family: 'Nunito-Regular';
  color: var(--colors-secondary-alternative);
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 5px;
}

.SignUpFormTextUnderline {
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.SignUpFormInputContainer {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: var(--colors-white-absolute);
  border-style: solid;
  border-color: var(--colors-grey-semitransparent);
  border-width: 1px;
  border-radius: 25px;
  box-shadow: 0px 3px 0px var(--colors-grey-ultratransparent);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SignUpFormDateContainer {
  padding-left: 52px;
  width: 100%;
  display: flex;
}

.SignUpFormDatePicker {
  display: flex;
  border: none;
  font-family: 'Nunito-Regular';
  width: 220px;
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: left;
  outline: none;
}

.SignUpFormDatePicker::-webkit-input-placeholder {
  color: var(--colors-grey-light);
}

.SignUpFormDatePicker::placeholder {
  color: var(--colors-grey-light);
}

.SignUpFormDateIcon {
  position: absolute;
  right: 27px;
  top: 14px;
}

.SignUpFormTermContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 2px;
}

@media (max-width: 1600px) {
  .SignUpFormInputContainer {
    height: 40px;
  }
  .SignUpFormDateIcon {
    top: 9px;
  }
}



.AdmissionStartContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.AdmissionStartBackground {
  background-image: url(/static/media/background-login.1eaa3d5d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 960px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.AdmissionStartFormContainer {
  margin-top: -70px;
  min-width: 320px;
}

.AdmissionStartSentence {
  margin-bottom: 10px;
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-absolute);
  text-align: center;
}

.AdmissionStartButtons {
  margin-top: 40px;
}

.AdmissionStartButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: block;
}

.ButtonWhite {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color:white;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 30px;
  color: #6A4DD1;
  text-align: center;
  border-color: #6A4DD1;
  border: solid;
  border-width: 1px;
  outline: none;
  cursor: pointer;
}

.ButtonLargeWhite {
  width: 226px;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 48px;
}

.ButtonWhite:active {
  background-color: rgb(111, 89, 190);
}

.AdmissionQuestionContainer {
  background-color: var(--colors-white-default);
  min-height: 100vh;
}

.AdmissionQuestionBody {
  display: flex;
  flex-direction: row;
  width: 70%;
  transform: translateX(-5%);
  margin-left: auto;
  margin-right: auto;
}

.AdmissionQuestionAnswersContainer {
  background-color: var(--colors-white-absolute);
  width: 50%;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: 15px;
  margin-top: 20px;
}

.AdmissionQuestionButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 95%;
  height: 100%;
  padding-top: 24px;
}

.AdmissionQuestionCorner {
  position: absolute;
  width: 360px;
  right: 0px;
  z-index: 1;
}

@media (max-width: 800px) {
  .AdmissionQuestionAnswersContainer {
    width: 100%;
    transform: translateX(0%);
    margin-left: 0;
  }

  .AdmissionQuestionCorner {
    visibility: hidden;
  }
  .AdmissionQuestionBody {
    flex-direction: column;
    justify-content: center;
    transform: translateX(0);
    width: 90%;
  }
}

.HeaderContainer {
  height: 78px;
  background-color: var(--colors-white-absolute);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderImage {
  display: flex;
  flex-direction: row;
}

.HeaderImageWithTitle {
  position: absolute;
  left: 20px;
}

.HeaderImageNoTitle {
  position: absolute;
}

.HeaderImageTitle {
  margin-left: 10px;
  font-family: 'Nunito-Light';
  font-size: 30px;
}

.HeaderTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
}

.HeaderDropShadow {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

@media (max-width: 800px) {
  .HeaderTitle {
    visibility: hidden;
  }
}
.PaginationContainer {
  padding-top: 17px;
  flex-direction: row;
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  height: 67px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.PaginationNumbersContainer {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media (max-width: 800px) {
  .PaginationContainer {
    width: 100%;
    transform: translateX(0%);
  }
}
.PaginationNumbersContainer {
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.PaginationNumbersNumber {
  font-family: 'Nunito-Bold';
  font-size: 14px;
  margin-top: 0;
  color: var(--colors-primary-default);
}

.PaginationNumbersLastNumber {
  color: var(--colors-secondary-alternative);
}

@media (max-width: 360px) {
  .PaginationNumbersContainer {
    padding-left: 10px;
  }
}


.PaginationCounterContainer {
  margin-left: auto;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.PaginationCounterAmount {
  padding-top: 2px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

.PaginationCounterImage {
  margin-left: 8px;
  margin-top: 12px;
  width: 14px;
  height: 18px;
}


.QuestionContainer {
  margin-top: 20px;
  background-color: var(--colors-white-absolute);
  width: 50%;
  min-height: 80px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.QuestionText {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Nunito-Regular';
  font-size: 25px;
  line-height: 30px;
  color: var(--colors-black-default);
  text-align: left;
  width: 90%;
}

@media (max-width: 800px) {
  .QuestionContainer {
    width: 100%;
    transform: translateX(0%);
  }
}

.QuestionImageContainer {
  width: 90%;
  height: 180px;
  overflow: hidden;
  padding-bottom: 20px;
}

.QuestionImage {
  flex: 1 1;
  max-width: 100%;
  max-height: 100%;
}
.AnswersContainer {
  margin-left: 41px;
  margin-right: 41px;
}

.AnswersSpaceBetween {
  margin-top: 20px;
}
.AnswerContainer {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  padding-top: 15px;
}

.AnswerImage {
  margin-right: 20px;
  width: 150px;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
}

.AnswerAroundText {
  display: inline-flex;
  min-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 14px;
  padding-right: 14px;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 2px 2px 1px var(--colors-black-ultratransparent);
}

.AnswerSelected {
  border: solid;
  border-width: 3px;
  border-color: var(--colors-status-selected)
}

.AnswerIcon {
  margin-right: 12;
  margin-top: 4px;
}

.AnswerText {
  display: flex;
  padding-left: 10px;
  padding-top: 6px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
  text-align: left;
}

.AnswerSelectedText {
  color: var(--colors-status-selected);
}
.AdmissionResultContainer {
  background-color: var(--colors-white-default);
  min-height: 100vh;
}

.AdmissionResultCorner {
  position: absolute;
  width: 360px;
  right: 0px;
  z-index: 1;
}

.AdmissionResultLevelText {
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  min-height: 67px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito-Regular';
  font-size: 20px;
  line-height: 30px;
  color: var(--colors-black-default);
}

.AdmissionResultDetails {
  display: flex;
  flex-direction: row;
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  min-height: 67px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.AdmissionResultButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  transform: translateX(-5%);
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

@media (max-width: 900px) {
  .AdmissionResultDetails {
    flex-direction: column;
    width: 100%;
    transform: translateX(0%);
  }
  .AdmissionResultLevelText {
    width: 100%;
    transform: translateX(0%);
  }
  .AdmissionResultCorner {
    visibility: hidden;
  }
  .AdmissionResultButton {
    width: 100%;
    transform: translateX(0%);
  }
}

.LevelCirclesContainer {
  position: relative;
  top: 70px;
  left: 150px;
  width: 210px;
  height: 210px;
}

.LevelCirclesContainerNoBar {
  position: relative;
  width: 210px;
  height: 210px;
}

.LevelCirclesBackground {
  position: relative;
  top: 0px;
  left: 0px;
  width: 210px;
  height: 210px;
}

.LevelCirclesProfile {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  overflow: hidden;
  object-fit: cover;
}

@media (max-width: 900px) {
  .LevelCirclesContainer {
    position: relative; left: 50%;
    transform: translateX(-50%);
  }
}
.LevelBarButton {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  left: 10px;
  top: 155px;
}

.LevelBarContainer {
  min-width: 138px;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
}

.LevelBarText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 35px;
  color: var(--colors-white-absolute);
}

.LevelBarProfile {
  left: 0px;
  top: 120px;
}

.LevelBarContainerProfile {
  min-width: 100px;
}
.LevelExplanationContainer {
  background-color: var(--colors-white-absolute);
  margin-top: 45px;
  margin-left: 250px;
  margin-right: 150px;
  margin-bottom: 45px;
}

.LevelExplanationTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  line-height: 22px;
  color: var(--colors-black-default);
}

.LevelExplanationText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 22px;
  color: var(--colors-black-default);
}

@media (max-width: 900px) {
  .LevelExplanationContainer {
    margin-top: 80px;
    margin-left: 50px;
    margin-right: 50px;
  }
}
.AboutContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.AboutBackground {
  background-image: url(/static/media/background-login.1eaa3d5d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1180px;
  width: 100%;
  height: 120%;
  position: absolute;
  z-index: -1;
}

.AboutFormContainer {
  min-width: 320px;
  margin-top: -60px;
}

.AboutText {
  white-space: pre-wrap;
  font-family: 'Nunito-Regular';
  font-size: 15px;
  line-height: 20px;
  max-width: 320px;
  text-align: justify;
  color: var(--colors-black-default);
  margin-bottom: 20px;
}
.MatchesContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.MatchesCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.MatchesProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

@media (max-width: 900px) {
  .MatchesCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .MatchesProfileImage {
    visibility: hidden;
  }
}

.ProfileWithLevelContainer {
  position: relative;
  width: 150px;
  height: 150px;
}

.ProfileWithLevelProfile {
  position: absolute;
  left: 5px;
  top: 0px;
  width: 140px;
  height: 140px;
  border-radius: 86px;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}
.__react_modal_image__header {
  background-color: transparent !important;
  top: 10px !important;
  right: 10px !important;
}

.__react_modal_image__icon_menu svg {
  width: 40px;
  height: 40px;
}

.ModalImageArrowsContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  pointer-events: none;
}

.ModalImageArrowLeft {
  position: absolute;
  z-index: 1001;
  left: 20px;
  top: calc(50% - 15px);
  width: 50px;
  height: 30px;
  background-color: transparent;
  color: white;
  font-family: 'Nunito-Bold';
  font-size: 40px;
  cursor: pointer;
  pointer-events: all;
}

.ModalImageArrowRight {
  position: absolute;
  z-index: 1001;
  right: 20px;
  top: calc(50% - 15px);
  width: 50px;
  height: 30px;
  background-color: transparent;
  color: white;
  font-family: 'Nunito-Bold';
  font-size: 40px;
  cursor: pointer;
  pointer-events: all;
}

.ModalImageNoSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none; 
}
.TitleBar {
  display: flex;
  flex-direction: column;
  min-height: 88px;
  width: 65%;
  margin-left: 8%;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  position: relative;
}

.TitleBarSmallLeftMargin {
  margin-left: 20px;
  width: 67%;
}

.TitleBarText {
  font-family: 'Nunito-Light';
  font-size: 30px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 25px;
}

.TitleBarLine {
  margin-left: 25px;
  min-height: 1px;
  width: 30px;
  background-color: var(--colors-primary-default);
}

@media (max-width: 900px) {
  .TitleBar {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
  .TitleBarText {
    margin-left: 40px;
  }
  .TitleBarLine {
    margin-left: 68px;
  }
}
.MatchesListList {
  margin-left: 20px;
  margin-top: 20px;
  max-width: 1204px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.MatchesListContentContainer {
  margin-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.MatchesListButtonContainer {
  margin-left: 20px;
  margin-top: 20px;
  max-width: 65%;
  min-height: 200px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MatchesText {
  margin-left: 6%;
  padding-top: 34px;
  font-family: 'Nunito-Regular';
  font-size: 16px;
  text-align: left;
}

@media (max-width: 900px) {
  .MatchesListList {
    margin-left: 0;
  }

  .MatchesListButtonContainer {
    margin-left: 0;
    max-width: 100%;
  }

  .MatchesListContentContainer {
    flex-direction: column;
    align-items: center;
  }
}
.MatchContainer {
  width: 30%;
  position: relative;
}

.MatchButton {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.MatchAspect {
  position: relative;
  width: 100%;
}

.MatchImageContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.MatchBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MatchProfileImg {
  position: absolute;
  top: 17%;
  left: 18%;
  width: 60%;
  height: 60%;
  border-radius: 100%;
  object-fit: cover;
}

.MatchPlay {
  bottom: 45%;
  left: 50%;
  transform: translateX(-58%);
  position: absolute;
}

@media (max-width: 900px) {
  .MatchContainer {
    width: 70%;
  }
}
.ButtonPlayButton {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.ButtonPlayContainer {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
}

.ButtonPlayContainerAlternative {
  background-color: var(--colors-primary-default);
}

.ButtonPlayIcon {
  width: 20px;
  height: 20px;
}
.MatchProfileContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.MatchProfileCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.MatchProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.MatchProfileEditBackButton {
  position: absolute;
  top: 125px;
  left: 30px;
}

.MatchProfileContent {
  margin-left: 6%;
}

.MatchProfileContentRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}

.MatchProfileBoxLeft {
  max-width: 530px;
  min-width: 400px;
  min-height: 910px;
  width: 30%;
  height: 910px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.MatchProfileBoxRight {
  position: relative;
  max-width: 820px;
  min-width: 450px;
  min-height: 910px;
  width: 50%;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.MatchProfileLike {
  position: absolute;
  top: 40px;
  right: 8%;
  z-index: 3;
}

.MatchProfileDreamCircle {
  margin-top: 25px;
}

.MatchProfileMorePictures {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 80px;
}

.MatchProfileProfileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.MatchProfileNameAge {
  margin-top: 0;
}

.MatchProfileHeadingWithIcon {
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.MatchProfileMentors {
  margin-top: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.MatchProfileSoundBar {
  margin-top: 14px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .MatchProfileImage {
    visibility: hidden;
  }
  .MatchProfileMorePictures {
    margin-top: 40px;
  }
  .MatchProfileBoxLeft {
    height: 810px;
    min-height: 810px;
  }
  .MatchProfileBoxRight {
    min-height: 810px;
  }
}

@media (max-width: 1200px) {
  .MatchProfileLike {
    top: 60px;
    right: 10px;
  }
  .MatchProfileEditBackButton {
    left: 10px;
  }
}

@media (max-width: 900px) {
  .MatchProfileContent {
    margin-left: 0;
  }
  .MatchProfileContentRow {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .MatchProfileCorner {
    visibility: hidden;
  }
  .MatchProfileMorePictures {
    margin-top: 20px;
  }
  .MatchProfileBoxLeft {
    min-width: 320px;
    height: 610px;
    min-height: 610px;
  }
  .MatchProfileBoxRight {
    min-width: 320px;
  }

  .MatchProfileLike {
    top: 10px;
    right: 2px;
  }
  .MatchProfileEditBackButton {
    left: 5px;
  }
}
.SubheadingContainer {
  display: flex;
  flex-direction: row;
}

.SubheadingTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  text-align: left;
  padding-left: 28px;
  margin-bottom: -10px;
  color: var(--colors-black-absolute);
}

.SubheadingTitleCenter {
  justify-content: center;
  text-align: center;
  padding-left: 0;
}

.SubheadingTitleLarge {
  font-size: 30px;
}

.SubheadingTitleRequired {
  margin-left: 3px;
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
}
.DreamCircleContainer {
  width: 100%;
  height: 204px;
  display: flex;
  justify-content: center;
}

.DreamCircleAroundPhoto {
  max-width: 234px;
  max-height: 234px;
  width: 48%;
  height: 0;
  padding-bottom: 48%;
  position: relative;
}

.DreamCircleDashedBorder {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-color: var(--colors-primary-default);
  border-width: 1px;
  border-style: dashed;
  background-color: var(--colors-white-ultratransparent);
}

.DreamCircleImage {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 99%;
  height: 99%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.DreamCircleText {
  font-family: 'Nunito-Bold';
  color: var(--colors-secondary-default);
  position: absolute;
  width: 72%;
  height: 72%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

@media (min-width: 1922px) {
  .DreamCircleAroundPhoto {
    width: 45%;
    padding-bottom: 45%;
  }
}


@media (max-width: 900px) {
  .DreamCircleContainer {
    height: 254px;
  }
}

.ProfileMorePhotosContainer{
  width: 100%;
  height: 100%;
}

.ProfileMorePhotosListContainer {
  margin-top: 20px;
  margin-left: 6%;
  width: 90%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.MorePhotoCircleContainer {
  position: relative;
  width: 37%;
  height: 0;
  padding-bottom: 37%;
  margin-top: 20px;
}

.MorePhotoCircleAroundPhoto {
  width: 100%;
  height: 100%;
}

.MorePhotoCircleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  box-shadow: 0px 3px 6px var(--colors-primary-default);
}

@media (min-width: 1922px) {
  .MorePhotoCircleContainer {
    width: 35%;
    padding-bottom: 35%;
  }
}

@media (max-width: 900px) {
  .MorePhotoCircleContainer {
    width: 28%;
    padding-bottom: 28%;
  }
}

.ProfileImageContainer {
  position: relative;
  width: 310px;
  height: 310px;
}

.ProfileImageBackground {
  position: relative;
  top: 0px;
  left: 0px;
  width: 310px;
  height: 310px;
}

.ProfileImageProfile {
  position: absolute;
  left: 50px;
  top: 45px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
}
.SoundBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SoundBarImage {
  height: 120px;
  width: 60%;
  margin-left: 0px;
  margin-right: 20px;
}

.SoundBarLength {
  font-family: 'Nunito-Light';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
}
.HeadingWithIconContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.HeadingWithIconLeftColumn {
  width: 40px;
}

.HeadingWithIconTitles {
  display: flex;
  flex-direction: column;
}

.HeadingWithIconHeading {
  font-family: 'Nunito-Light';
  font-size: 26px;
  line-height: 36px;
  color: var(--colors-black-absolute);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
  text-align: left;
}

.HeadingWithIconSubtitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  line-height: 38px;
  color: var(--colors-black-absolute);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
  text-align: left;
}

@media (max-width: 1200px) {
  .HeadingWithIconTitles {
    flex-direction: column;
  }
}
.InspiringPeopleListItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
}

.InspiringPeopleListItemAroundIcon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-primary-default);
}

.InspiringPeopleListItemName {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 14px;
  font-family: 'Nunito-Light';
  font-size: 18px;
  color: var(--colors-secondary-alternative);
}


.InspiringPeopleListContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.BackButton {
  width: 20px;
  height: 35px;
  cursor: pointer;
}
.ButtonCirclePurpleButton {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ButtonCirclePurpleButton :hover + #ButtonCirclePurpleAlt {
  color: var(--colors-black-default);
}

.ButtonCirclePurpleContainer {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
}

.ButtonCirclePurpleMedium {
  width: 60px;
  height: 60px;;
}

.ButtonCirclePurpleIcon {
  width: 40px;
  height: 40px;
}

.ButtonCirclePurpleIconMedium {
  width: 30px;
  height: 30px;
}

.ButtonCirclePurpleIconSmall {
  width: 20px;
  height: 20px;
}

.ButtonCirclePurpleAltText {
  position: absolute;
  color: transparent;
  white-space: pre-wrap;
  font-family: 'Nunito-Regular';
  font-size: 12px;
  min-width: 120px;
  max-width: 120px;
  left: -20px;
}
.ProfileContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  min-height: 100vh;
}

.ProfileCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.ProfileProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ProfileContent {
  margin-left: 8%;
}

.ProfileContentRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}

.ProfileBoxLeft {
  max-width: 530px;
  min-width: 400px;
  min-height: 910px;
  width: 30%;
  height: 910px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileBoxRight {
  max-width: 820px;
  min-width: 450px;
  min-height: 910px;
  width: 50%;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileDreamCircle {
  margin-top: 25px;
}

.ProfileMorePictures {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 80px;
}

.ProfileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.ProfileNameAge {
  margin-top: 0;
}

.ProfileHeadingWithIcon {
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.ProfileMentors {
  margin-top: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.ProfileEditButtonContainer {
  margin-top: 50px;
  margin-bottom: 30px;
}

.ProfileSoundBar {
  margin-top: 14px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .ProfileProfileImage {
    visibility: hidden;
  }
  .ProfileMorePictures {
    margin-top: 40px;
  }
  .ProfileBoxLeft {
    height: 810px;
    min-height: 810px;
  }
  .ProfileBoxRight {
    min-height: 810px;
  }
}

@media (max-width: 900px) {
  .ProfileContent {
    margin-left: 0;
  }
  .ProfileContentRow {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .ProfileCorner {
    visibility: hidden;
  }
  .ProfileMorePictures {
    margin-top: 20px;
  }
  .ProfileBoxLeft {
    min-width: 320px;
    height: 610px;
    min-height: 610px;
  }
  .ProfileBoxRight {
    min-width: 320px;
  }
}
.ProfileEditBackButton {
  position: absolute;
  top: 125px;
  left: 30px;
}

.ProfileEditContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
}

.ProfileEditCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.ProfileEditProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ProfileEditSubtitle {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  color: var(--colors-grey-dark);
  font-family: 'Nunito-Light';
  font-size: 12px;
}

.ProfileEditSubtitleRed {
  color: var(--colors-status-danger);
  padding-right: 3px;
}

.ProfileEditSubtitleText {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .ProfileEditCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .ProfileEditProfileImage {
    visibility: hidden;
  }
}
.ProfileInfoEditContainer {
  margin-left: 8%;
}

.ProfileInfoEditRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}
.ProfileInfoEditRowFirst {
 padding-top: 10px;
}

.ProfileInfoEditBox {
  min-width: 470px;
  min-height: 400px;
  width: 470px;
  height: 400px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileInfoEditBoxSecond {
  min-height: 375px;
  height: 375px;
}

.ProfileInfoEditBoxFlexHeigth {
  height: auto;
}

.ProfileInfoBoxContent {
  width: 100px;
  height: 100px;
}

@media (max-width: 900px) {
  .ProfileInfoEditContainer {
    margin-left: 0;
  }
  .ProfileInfoEditRow {
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 660px) {
  .ProfileInfoEditBox {
    min-width: 320px;
    width: 80%;
  }
}

.PhotoCircleListContainer{
  width: 100%;
  height: 100%;
}

.PhotoCircleListPhotosContainer {
  margin-top: 50px;
  margin-left: 20px;
  width: 90%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PhotoCircleContainer {
  position: relative;
  width: 127px;
  height: 127px;
}

.PhotoCircleAroundPhoto {
  width: 100%;
  height: 100%;
}

.PhotoCircleDashedBorder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-color: var(--colors-primary-default);
  border-width: 1px;
  border-style: dashed;
}

.PhotoCircleImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.PhotoCircleButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  background-color: var(--colors-secondary-alternative);
  border-width: 0;
  outline: none;
  cursor: pointer;
}

.PhotoCircleButtonWhite {
  background-color: var(--colors-white-absolute);
}

.PhotoCircleDream {
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.PhotoCircleButtonImage {
  /* no style for now */
  width: 14px;
  height: 14px;
}

.PhotoCircleInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  outline: none;
  opacity:0.0;
  cursor: pointer;
}

.PhotoCircleRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 0;
  top: -30px;
}

@media (max-width: 550px) {
  .PhotoCircleContainer {
    position: relative;
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 470px) {
  .PhotoCircleContainer {
    position: relative;
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 420px) {
  .PhotoCircleContainer {
    position: relative;
    width: 85px;
    height: 85px;
  }
}
.DreamEditorContainer {
  display: flex;
  flex-direction: column;
}

.DreamEditorContent {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.DreamEditorInputContainer {
  width: 100%;
  height: 100%;
  padding-top: 75px;
}
.ProfileInputContainer {
  width: 100%;
  position: relative;
}

.ProfileInputInnerContainer {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.ProfileInputTextInput {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border-color: transparent;
  border: 1px solid var(--colors-primary-default);
  font-family: 'Nunito-Regular';
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  outline: none;
}
.ProfileInputTextInput::-webkit-input-placeholder {
  color: var(--colors-grey-light);
}
.ProfileInputTextInput::placeholder {
  color: var(--colors-grey-light);
}

.ProfileInputDeleteButton {
  position: absolute;
  right: 50px;
  top: 10px;
  outline: none;
  background-color: transparent;
  border: none;
}

.ProfileInputDeleteIcon {
  width: 15px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
}

.ProfileInputRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 24px;
  top: -26px;
}
.VoiceMessageRecorderButtonContainer {
  margin-top: 70px;
}

.VoiceMessageRecorderAnimation {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
}

.VoiceMessageRecorderPreview {
  width: 100%;
  margin-top: 40px;
}

.VoiceMessageRecorderText {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  color: var(--colors-black-default);
}
.AnimatedProgressBarContainer {
  height: 2px;
  border-radius: 1px;
  display: flex;
  align-items: stretch;
  background-color: var(--colors-grey-lighter);
}

.AnimatedProgressBarBar {
  height: 2px;
  /* width: 40px; */
  border-radius: 1px;
  background-color: var(--colors-primary-default);
}

.ButtonPrimaryButton {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.ButtonPrimaryContainer {
  width: 125px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-primary-alternative);
}

.ButtonPrimaryLargeContainer {
  max-width: 100%;
  width: 225px;
  height: 48px;
  border-radius: 24px;
}

.ButtonPrimaryLabelContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ButtonPrimaryLabel {
  padding-top: 7px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  color: var(--colors-white-absolute);
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}

.ButtonPrimaryLargeLabel {
  font-size: 18px;
  line-height: 30px;
}

.ButtonPrimaryIcon {
  margin-left: -23px;
  margin-right: 12px;
}
.PersonalInformationEditorContainer {
  display: flex;
  flex-direction: column;

}

.PersonalInformationEditorHeading {
  margin-bottom: 14px;
}

.PersonalInformationEditorInput {
  margin-top: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.PersonalInformationEditorInput > * {
  margin: 10px 0;
}

.PersonalInformationEditorDatePickerContainer{
  height: 40px;
  border-radius: 20px;
  border-color: transparent;
  border: 1px solid var(--colors-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
}

.PersonalInformationEditorDatePicker {
  display: flex;
  border: none;
  font-family: 'Nunito-Regular';
  width: 90px;
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  outline: none;
}

.PersonalInformationEditorDatePicker::-webkit-input-placeholder {
  color: var(--colors-grey-light);
}

.PersonalInformationEditorDatePicker::placeholder {
  color: var(--colors-grey-light);
}

.PersonalInformationEditorDateIcon {
  position: absolute;
  left: 27px;
  top: 10px;
}
.ProfileSelectInputContainer {
  width: 100%;
  position: relative;
}

.ProfileSelectInputInnerContainer {
  /* width: 100%; */
  height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid var(--colors-primary-default);
  border-radius: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.ProfileSelectInputSelector {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  height: 40px;
  border-color: transparent;
  font-family: 'Nunito-Regular';
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  text-align-last: center;
  outline: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.ProfileSelectInputTriangle {
	width: 0;
  height: 0;
  position: absolute;
  right: 25px;
  top: 15px;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
  border-top: 10px solid var(--colors-primary-default);
  pointer-events: none;
}

.ProfileSelectInputPlaceholder {
  color: var(--colors-grey-light);
}

.ProfileSelectInputDeleteButton {
  position: absolute;
  right: 50px;
  top: 10px;
  outline: none;
  background-color: transparent;
  border: none;
}

.ProfileSelectInputDeleteIcon {
  width: 15px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
}

.ProfileSelectInputRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 24px;
  top: -26px;
}

@media (max-width: 520px) {
  .ProfileSelectInputSelector {
    text-indent: 0 !important;
  }
}

.MentorsEditorContainer {
  display: flex;
  flex-direction: column;
}

.MentorsEditorContainer > * {
  margin: 10px 0;
  margin-left: 0;
  padding-left: 0;
}

.MentorsEditorHeading {
  margin-top: 0px;
  padding-bottom: 20px;
}

.MentorsEditorHeading {
  margin-bottom: 0px;
}

.MentorsEditorAddButton {
  margin-top: 16px;
  width: 100%;
}
.ButtonGreyOuterContainer {
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.ButtonGreyOuterContainerNoPadding {
  padding-left: 0;
  padding-right: 0;
}

.ButtonGreyContainer {
  width: 100%;
  height: 40px;
  margin-left: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  position: relative;
  outline: none;
  cursor: pointer;
}

.ButtonGreyLargeContainer {
  height: 48px;
  border-radius: 24px;
}

.ButtonGreySmallContainer {
  height: 30px;
  border-radius: 15px;
  width: 133px;
}

.ButtonGreyBorder {
  border-width: 1px;
  border-color: var(--colors-secondary-alternative);
}

.ButtonGreyLabel {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--colors-white-absolute);
  text-align: center;
}

.ButtonGreyLargeLabel {
  font-size: 15px;
}

.ButtonGreyIcon {
  position: absolute;
  right: 15px;
  top: 10px;
}

.ProfileFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1760px;
}

.ProfileFooterSaveCancelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ProfileFooterCancelButton {
  padding-left: 20px;
}
.FilterContainer {
  margin-left: 8%;
  margin-top: 40px;
  margin-right: 178px;
  max-width: 1460px;
}

.FilterRow {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 0px;
}

.FilterBox {
  min-width: 470px;
  min-height: 280px;
  width: 470px;
  height: 280px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.FilterValueContainer {
  margin-top: 64px;
}

.FilterValueText {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-default);
}

.FilterSlider {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
}

.FilterSaveButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.FilterGenderContainer {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .FilterContainer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FilterRow {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 660px) {
  .FilterBox {
    min-width: 320px;
    width: 80%;
  }
}
.FilterHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 67px;
  width: 100%;
  max-width: 1450px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.FilterHeaderText {
  font-family: 'Nunito-Light';
  font-size: 30px;
  text-align: left;
  padding-left: 25px;
}


@media (max-width: 900px) {
  .TitleBar {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
}
.ChatContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.ChatCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.ChatProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ChatHistoryButton {
  position: absolute;
  right: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}

.ChatHistoryIcon {
  margin-right: 20px;
}

.ChatHistoryText {
  font-family: 'Nunito-Light';
  font-size: 20px;
  box-shadow: inset 0 0px 0 white, inset 0 -1px 0 var(--colors-secondary-default);
  color: var(--colors-secondary-default);
}

.ChatList {
  flex-grow : 1;
  display: flex;
  flex-flow: column;
}

.ChatInput {
  height: 48px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.ChatBottomSpace {
  height: 20px;
}

@media (max-width: 900px) {
  .ChatContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .ChatCorner {
    visibility: hidden;
  }
  .ChatHistoryText {
    font-size: 14px;
  }

  .ChatInput {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1400px) {
  .ChatProfileImage {
    visibility: hidden;
  }
}
.TitleChat {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 88px;
  width: 65%;
  margin-left: 8%;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  position: relative;
}

.TitleChatProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 30px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

.TitleChatName {
  font-family: 'Nunito-Regular';
  font-size: 20px;
}

.TitleChatStopButton {
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.TitleChatStopText {
  font-family: 'Nunito-Light';
  font-size: 14px;
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .TitleChatProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .TitleChat {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
  .TitleChatName {
    font-size: 17px;
  }
}

@media (max-width: 400px) {
  .TitleChatName {
    font-size: 14px;
  }
}
.ChatListContainer {
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  flex-grow : 1;
  margin-top: 20px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

@media (max-width: 900px) {
  .ChatListContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
}
.ChatBoxProfileImageButton {
  width: 64px;
  min-width: 64px;
  height: 54px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 100%;
  margin-top: -5px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.ChatBoxProfileImage {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

@media (max-width: 900px) {
  .ChatBoxProfileImageButton {
    margin-left: 10px;
    margin-right: 10px;
    width: 54px;
    height: 44px;
    min-width: 54px;
  }
}

.ChatBoxTextContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxTextMessageContainer {
  max-width: 80%;
  padding-top: 12px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  box-sizing: border-box;
}

.ChatBoxTextMessageContainerPartner {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatBoxTextText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.ChatBoxTextDateText {
  color: var(--colors-grey-light);
  font-family: 'Nunito-Regular';
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: 5px;
}

@media (max-width: 900px) {
  .ChatBoxTextContainer {
    padding-right: 10px;
  }
}

.ChatBoxPhotoContainer {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxPhotoChatPhoto {
  height: 200px;
  max-width: 70%;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  background-color: var(--colors-grey-light);
}

.ChatBoxVoiceContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ChatBoxVoiceWrapper {
  padding-top: 12px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  box-sizing: border-box;
}

.ChatBoxVoiceWrapperPartner {
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatBoxVoiceVoiceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ChatBoxVoiceWaveform {
  height: 50px;
  max-width: 80%;
  object-fit: cover;
}

.ChatBoxVoiceTextDate {
  color: var(--colors-grey-light);
  font-family: 'Nunito-Regular';
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: 5px;
}

@media (max-width: 400px) {
  .ChatBoxVoiceWaveform {
    height: 30px;
  }
}
.ChatInputContainer {
  width: 65%;
  margin-left: 8%;
  height: 48px;
  border-radius: 16px;
  background-color: var(--colors-white-absolute);
  display: flex;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatInputInputWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ChatInputInput {
  display: flex;
  font-family: 'Nunito-Regular';
  font-size: 15px;
  margin-left: 25px;
  margin-right: 10px;
  color: var(--colors-grey-medium);
  width: 100%;
  border: none;
  box-sizing: border-box;
  outline: none;
}

.ChatInputInput::-webkit-input-placeholder {
  color: var(--colors-grey-light);
}

.ChatInputInput::placeholder {
  color: var(--colors-grey-light);
}

.ChatInputButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.ChatInputLastButton {
  margin-right: 20px;
}

.ChatInputInputIcon {
  object-fit: contain;
  height: 20px;
  width: 25px;
}

.ChatInputRecorderAnimation {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
}

@media (max-width: 900px) {
  .ChatInputContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
}
.ChatHistoryContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.ChatHistoryCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.ChatHistoryProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

@media (max-width: 900px) {
  .ChatHistoryContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .ChatHistoryCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .ChatHistoryProfileImage {
    visibility: hidden;
  }
}
.ChatHistoryListContainer {
  width: 65%;
  margin-left: 8%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .ChatHistoryListContainer {
    width: 100%;
    margin-left: 0;
  }
}
.ChatHistoryCardButton {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.ChatHistoryCardContainer {
  height: 80px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.ChatHistoryCardProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 30px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}

.ChatHistoryCardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ChatHistoryCardContentRow {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.ChatHistoryCardUserInfo {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-right: 20px;
  white-space: nowrap;
  color: var(--colors-black-default);
}

.ChatHistoryCardDate {
  font-family: 'Nunito-Regular';
  font-size: 12px;
  color: var(--colors-grey-light);
}

.ChatHistoryCardStatus {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

@media (max-width: 1100px) {
  .ChatHistoryCardProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }

  .ChatHistoryCardContentRow {
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .ChatHistoryCardUserInfo {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .ChatHistoryCardContentRow {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 400px) {
  .ChatHistoryCardUserInfo {
    font-size: 14px;
  }
}

.SettingsContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.SettingsCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.SettingsContent {
  margin-top: 20px;
  min-height: 630px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.SettingsButtonsContainer {
  padding-top: 62px;
  margin-left: 6%;
  margin-bottom: 50px;
  width: 90%;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.SettingsButtonLeft {
  margin-right: 15px;
  width: 100%;
}

.SettingsButtonRight {
  margin-left: 15px;
  width: 100%;
}

.SettingsAroundQuote {
  width: 90%;
  max-width: 500px;
  margin-left: 6%;
}

.SettingsButtonLogout {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 240px;
  margin-left: 6%;
}

@media (max-width: 900px) {
  .SettingsContent {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .SettingsCorner {
    visibility: hidden;
  }
  .SettingsButtonsContainer {
    margin-left: auto;
    margin-right: auto;
  }
  .SettingsAroundQuote {
    margin-left: auto;
    margin-right: auto;
  }
  .SettingsButtonLogout {
    margin-left: auto;
    margin-right: auto;
  }
}
.QuoteContainer {
  position: relative;
  width: 100%;
  height: 340px;
  border-radius: 7px;
  overflow: hidden;
}

.QuoteBackgroiundImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 340px;
  object-fit: cover;
  filter: blur(20px);
  -webkit-filter: blur(20px);
}

.QuoteDarkOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--colors-black-transparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 7px;
}

.QuoteImage {
  align-self: center;
}

.QuoteContentTextTitle {
  font-family: 'Nunito-Regular';
  text-align: center;
  font-size: 40px;
  color: var(--colors-white-default);
}

.QuoteContentText {
  font-family: 'Nunito-Light';
  text-align: center;
  font-size: 32px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--colors-white-default);
}
.InvitationsContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.InvitationsCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.InvitationsProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

@media (max-width: 900px) {
  .InvitationsCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .InvitationsProfileImage {
    visibility: hidden;
  }
}
.InvitationsListContainer {
  width: 65%;
  margin-left: 8%;
}

.InvitationsListTabs {
  margin-bottom: 16px;
}

@media (max-width: 900px) {
  .InvitationsListContainer {
    width: 100%;
    margin-left: 0;
  }
}
.InvitationTabsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center
}
.TabSingleButton {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.TabSingleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TabSingleTitle {
  font-family: 'Nunito-Light';
  font-size: 20px;
  color: var(--colors-black-absolute);
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.TabSingleTitleActive {
  font-family: 'Nunito-Light';
  color: var(--colors-secondary-alternative);
}

.TabSingleVerticalBar {
  width: 1px;
  height: 21px;
  background-color: var(--colors-primary-default);
}

.TabSingleVerticalLeft {
  margin-right: 10px;
}

.TabSingleVerticalRight {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .TabSingleTitle {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.InvitationCardButton {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.InvitationCardContainer {
  height: 80px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.InvitationCardProfilePicture {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-left: 34px;
  margin-right: 56px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}

.InvitationCardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.InvitationCardContentRow {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.InvitationCardStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InvitationCardUserInfo {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-right: 20px;
  white-space: nowrap;
  color: var(--colors-black-default);
}

.InvitationCardDate {
  font-family: 'Nunito-Regular';
  font-size: 12px;
  color: var(--colors-grey-light);
}

.InvitationCardCircle {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 5px;
}

.InvitationCardStatus {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-medium);
}

@media (max-width: 1100px) {
  .InvitationCardProfilePicture {
    margin-left: 5%;
    margin-right: 5%;
  }

  .InvitationCardContentRow {
    margin-right: 5%;
  }
}

@media (max-width: 900px) {
  .InvitationCardUserInfo {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .InvitationCardContentRow {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 400px) {
  .InvitationCardUserInfo {
    font-size: 14px;
  }
}


.SubscriptionCard {
  width: 300px;
  height: 390px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 10px;
}

.SubscriptionCardActive {
  border:1px solid var(--colors-primary-alternative)
}

.SubscriptionCardSeparator {
  height: 1px;
  margin-left: 21px;
  margin-right: 21px;
  background-color: var(--colors-secondaty-alternative4);
  margin-bottom: 42px;
}

.SubscriptionCardTitle {
  font-family: 'Nunito-Bold';
  font-size: 20px;
  margin-top: 21px;
  margin-bottom: 23px;
}

.SubscriptionCardPrice {
  font-family: 'Nunito-Bold';
  font-size: 30px;
  color: var(--colors-primary-alternative);
  margin-top: 0;
  margin-bottom: 3px;
}

.SubscriptionCardInterval {
  font-family: 'Nunito-Bold';
  font-size: 16px;
  color: var(--colors-primary-alternative);
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.SubscriptionCardDescription {
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: var(--colors-secondary-default);
  margin-top: 0;
  margin-bottom: 16px;
}

.SubscriptionCardStatus {
  margin-top: 28px;
}

.SubscriptionCardStatusDescription {
  font-family: 'Nunito-Bold';
  font-size: 17px;
  color: var(--colors-primary-alternative);
  text-transform: uppercase;
  padding-top: 30px;
}
.ButtonRed {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-tertiary-alternative2);
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 30px;
  color: var(--colors-white-absolute);
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.ButtonSmallRed {
  width: 133px;
}

.ButtonRedFill {
  width: 100%;
}

.ButtonLargeRed {
  width: 226px;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 48px;
}

.ButtonRedIcon {
  margin-left: 25px;
}

.ButtonRed:active {
  background-color: var(--colors-secondary-alternative2);
}



.SubscriptionContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.SubscriptionCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.SubscriptionActive {
  margin-top: 20px;
  min-height: 80px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SubscriptionActiveStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.SubscriptionActiveCircle {
  border-radius: 100%;
  min-width: 10px;
  min-height: 10px;
  background-color: var(--colors-status-selected);
  margin-left: 28px;
  margin-right: 8px;
}

.SubscriptionActiveText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-text);
}

.SubscriptionActiveCancel {
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 28px;
  padding-left: 5px;
}

.SubscriptionContent {
  margin-top: 20px;
  min-height: 500px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 8px;
}

.SubscriptionContentCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SubscriptionDescription {
  margin: 0;
  padding-top: 32px;
  padding-bottom: 20px;
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: var(--colors-secondary-default);
}

@media (max-width: 900px) {
  .SubscriptionCorner {
    visibility: hidden;
  }

  .SubscriptionActive {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }

  .SubscriptionContent {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
}

.MenuContainer {
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--colors-primary-default);
  width: 123px;
  height: 100%;
  min-height: 520px;
  overflow: hidden;
  z-index: 2;
}

.MenuContainerOpen {
  width: 400px;
  align-items: flex-start;
  padding-left: 43px;
  box-sizing: border-box;
}

.MenuHeader {
  height: 62px;
  width: 100%;
  font-family: 'Nunito-Light';
  font-size: 30px;
  color: var(--colors-white-absolute);
}

.MenuHeaderOpened {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MenuTitleLine {
  position: absolute;
  top: 80px;
  left: 45px;
  min-height: 1px;
  width: 30px;
  background-color: var(--colors-secondary-alternative);
}

.MenuCloseButton {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 30px;
}

.MenuItemsContainer {
  display: flex;
  margin-top: 75px;
  flex-direction: column;
  align-items: flex-start;
  height: 360px;
  justify-content: space-between;
}

.MenuItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

#MenuItemHoverContainer {
  position: absolute;
  padding-top: 21px;
  width: 100px;
  text-align: center;
  visibility: hidden;
  color: var(--colors-white-absolute);
}

.MenuItemHoverSelected {
  color: var(--colors-secondary-alternative) !important;
}

.MenuItemHoverContainerFix {
  padding-top: 11px !important;
}

.MenuItemsContainer :hover #MenuItemHoverContainer{
  visibility: visible;
}


.MenuItemBadge {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: var(--colors-tertiary-alternative2);
  position: absolute;
  left: 70px;
}

.MenuItemBadgeNumber {
  margin: 0;
  line-height: 16px;
  text-align: center;
  font-family: 'Nunito-Regular';
  font-size: 10px;
  color: var(--colors-white-absolute);
}

.MenuIcon {
  margin-top: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.MenuText {
  font-family: 'Nunito-Regular';
  font-size: 20px;
  margin-left: 35px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colors-white-absolute);
}

.MenuTextSelected {
  color: var(--colors-secondary-alternative);
}

.MenuLogoutContainer {
  position: absolute;
  bottom: 50px;
  left: 46px;
  width: 30px;
  height: 30px;
}

.MenuLogoutIcon {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 0;
}

.MenuLogoutContainer :hover ~ .MenuLogoutHoverContainer{
  visibility: visible;
}

.MenuLogoutHoverContainer {
  padding-top: 16px;
  margin-left: -16px;
  width: 100px;
  text-align: left;
  visibility: hidden;
  color: var(--colors-white-absolute);
}

.MenuLogoutText {
  position: absolute;
  font-family: 'Nunito-Regular';
  font-size: 20px;
  color: var(--colors-white-absolute);
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  bottom: 50px;
  left: 110px;
}

@media (max-width: 900px) {
  .MenuContainer {
    width: 60px;
    height: 78px;
    min-height: 78px;
    position: absolute;
  }
  .MenuContainerOpen {
    height: 100%;
    width: 260px;
  }
  .MenuPage {
    margin-left: 0px;
  }
  .MenuIcon {
    margin-right: 0;
  }
  .MenuLogoutIcon {
    visibility: hidden;
  }

  .MenuLogoutIconVisible {
    visibility: visible;
  }
}

@media (max-height: 600px) {
  .MenuItemsContainer {
    height: 288px;
  }

  #MenuItemHoverContainer {
    padding-top: 16px;
  }

  .MenuItemHoverContainerFix {
    padding-top: 8px !important;
  }
}
.ObverlayMatchContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.OverlayMatchBlur {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .OverlayMatchBlur {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

@supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .OverlayMatchBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.ObverlayMatchContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ObverlayMatchTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  margin-bottom: 3%;
  color: var(--colors-black-absolute);
}

.ObverlayMatchCircles {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 322px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/your-a-match-bg.5942cff3.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400px;
}

.ObverlayMatchPerson {
  position: absolute;
  max-height: 160px;
  max-width: 160px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  top: 75px;
}

.ObverlayMatchFirstPerson {
  position:relative;
  left: 15px;
  top: -6px;
}

.ObverlayMatchMatchPerson {
  position:relative;
  left: -5px;
  top: -6px;
}

.ObverlayMatchMatchText {
  margin-top: 0;
  margin-bottom: 5%;
  color: var(--colors-secondary-default);
  font-family: 'Nunito-Regular';
  font-size: 17px;
  text-align: center;
  white-space: pre-wrap;
}

.ObverlayMatchLaterButton {
  margin-top: 24px;
}
.InvitationModalContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.InvitationModalBlur {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .InvitationModalBlur {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

@supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .InvitationModalBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.InvitationModalContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.InvitationModalTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  margin-bottom: 3%;
  color: var(--colors-black-absolute);
}

.InvitationModalProfileContent {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 322px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.InvitationModalHeart {
  margin-left: 50px;
  width: 50px;
}

.InvitationModalText {
  margin-top: 30px;
  margin-bottom: 5%;
  color: var(--colors-secondary-default);
  font-family: 'Nunito-Regular';
  font-size: 17px;
  text-align: center;
  white-space: pre-wrap;
}

.InvitationModalCloseButton {
  margin-top: 24px;
}
.ActiveChatModalContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.ActiveChatModalBlur {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .ActiveChatModalBlur {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

@supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .ActiveChatModalBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.ActiveChatModalContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ActiveChatModalTitle {
  font-family: 'Nunito-Light';
  font-size: 30px;
  margin-bottom: 3%;
  color: var(--colors-black-absolute);
}

.ActiveChatModalProfileContent {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 322px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ActiveChatModalText {
  margin-top: 30px;
  margin-bottom: 5%;
  color: var(--colors-secondary-default);
  font-family: 'Nunito-Regular';
  font-size: 17px;
  text-align: center;
  white-space: pre-wrap;
}

.ActiveChatModalCloseButton {
  margin-top: 24px;
}
.SelectInputContainer {
  width: 100%;
  position: relative;
}

.SelectInputInnerContainer {
  /* width: 100%; */
  height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid var(--colors-primary-default);
  border-radius: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.SelectInputSelector {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  height: 40px;
  border-color: transparent;
  font-family: 'Nunito-Regular';
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  text-align-last: center;
  outline: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.SelectInputTriangle {
	width: 0;
  height: 0;
  position: absolute;
  right: 25px;
  top: 15px;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
  border-top: 10px solid var(--colors-secondary-alternative);
  pointer-events: none;
}

.SelectInputPlaceholder {
  color: var(--colors-grey-light);
}

.SelectInputDeleteButton {
  position: absolute;
  right: 50px;
  top: 10px;
  outline: none;
  background-color: transparent;
  border: none;
}

.SelectInputDeleteIcon {
  width: 15px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
}

.SelectInputRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 24px;
  top: -26px;
}

@media (max-width: 520px) {
  .SelectInputSelector {
    text-indent: 0 !important;
  }
}
.GoPremiumModalContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  visibility: hidden;
}

.GoPremiumModalBlur {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .GoPremiumModalBlur {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

@supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .GoPremiumModalBlur {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.GoPremiumModalContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GoPremiumModalBox {
  width: 514px;
  height: 540px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 10px;
}

.GoPremiumModalBoxPayment {
  height: 450px;
}

.GoPremiumForm {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.GoPremiumButtons {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.GoPremiumButtons > div
{
  margin-left: 25px;
}

.GoPremiumModalTitle {
  font-family: 'Nunito-Bold';
  font-size: 24px;
  color: var(--colors-secondary-alternative);
}

.GoPremiumModalInputTitle {
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-absolute);
  margin-bottom: 5px;
}

.GoPremiumModalCheckout {
  padding-top: 40px;
}

.GoPremiumModalComplete {
  padding-top: 80px;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 12px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media (max-width: 520px) {
  .GoPremiumModalBox {
    width: 95%;
  }
}
.App {
  text-align: center;
}

