@import url('../../config/colors.css');

.MatchProfileContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.MatchProfileCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.MatchProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.MatchProfileEditBackButton {
  position: absolute;
  top: 125px;
  left: 30px;
}

.MatchProfileContent {
  margin-left: 6%;
}

.MatchProfileContentRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}

.MatchProfileBoxLeft {
  max-width: 530px;
  min-width: 400px;
  min-height: 910px;
  width: 30%;
  height: 910px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.MatchProfileBoxRight {
  position: relative;
  max-width: 820px;
  min-width: 450px;
  min-height: 910px;
  width: 50%;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.MatchProfileLike {
  position: absolute;
  top: 40px;
  right: 8%;
  z-index: 3;
}

.MatchProfileDreamCircle {
  margin-top: 25px;
}

.MatchProfileMorePictures {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 80px;
}

.MatchProfileProfileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.MatchProfileNameAge {
  margin-top: 0;
}

.MatchProfileHeadingWithIcon {
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.MatchProfileMentors {
  margin-top: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.MatchProfileSoundBar {
  margin-top: 14px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .MatchProfileImage {
    visibility: hidden;
  }
  .MatchProfileMorePictures {
    margin-top: 40px;
  }
  .MatchProfileBoxLeft {
    height: 810px;
    min-height: 810px;
  }
  .MatchProfileBoxRight {
    min-height: 810px;
  }
}

@media (max-width: 1200px) {
  .MatchProfileLike {
    top: 60px;
    right: 10px;
  }
  .MatchProfileEditBackButton {
    left: 10px;
  }
}

@media (max-width: 900px) {
  .MatchProfileContent {
    margin-left: 0;
  }
  .MatchProfileContentRow {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .MatchProfileCorner {
    visibility: hidden;
  }
  .MatchProfileMorePictures {
    margin-top: 20px;
  }
  .MatchProfileBoxLeft {
    min-width: 320px;
    height: 610px;
    min-height: 610px;
  }
  .MatchProfileBoxRight {
    min-width: 320px;
  }

  .MatchProfileLike {
    top: 10px;
    right: 2px;
  }
  .MatchProfileEditBackButton {
    left: 5px;
  }
}