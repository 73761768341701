.ProfileImageContainer {
  position: relative;
  width: 310px;
  height: 310px;
}

.ProfileImageBackground {
  position: relative;
  top: 0px;
  left: 0px;
  width: 310px;
  height: 310px;
}

.ProfileImageProfile {
  position: absolute;
  left: 50px;
  top: 45px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
}