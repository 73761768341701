@import url('../../config/colors.css');

.ButtonPrimaryButton {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.ButtonPrimaryContainer {
  width: 125px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-primary-alternative);
}

.ButtonPrimaryLargeContainer {
  max-width: 100%;
  width: 225px;
  height: 48px;
  border-radius: 24px;
}

.ButtonPrimaryLabelContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ButtonPrimaryLabel {
  padding-top: 7px;
  font-family: 'Nunito-Regular';
  font-size: 14px;
  line-height: 14px;
  color: var(--colors-white-absolute);
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}

.ButtonPrimaryLargeLabel {
  font-size: 18px;
  line-height: 30px;
}

.ButtonPrimaryIcon {
  margin-left: -23px;
  margin-right: 12px;
}