@import url('../../config/colors.css');

.ButtonText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-secondary-alternative);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ButtonText:active {
  color: var(--colors-secondary-alternative3);
}