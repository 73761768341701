.LoginContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.LoginBackground {
  background-image: url('../../assets/images/background-login.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1080px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.LoginFormContainer {
  min-width: 320px;
}
