.ChatBoxProfileImageButton {
  width: 64px;
  min-width: 64px;
  height: 54px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 100%;
  margin-top: -5px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.ChatBoxProfileImage {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

@media (max-width: 900px) {
  .ChatBoxProfileImageButton {
    margin-left: 10px;
    margin-right: 10px;
    width: 54px;
    height: 44px;
    min-width: 54px;
  }
}
