@import url('../../config/colors.css');

.ProfileContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  min-height: 100vh;
}

.ProfileCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.ProfileProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ProfileContent {
  margin-left: 8%;
}

.ProfileContentRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}

.ProfileBoxLeft {
  max-width: 530px;
  min-width: 400px;
  min-height: 910px;
  width: 30%;
  height: 910px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileBoxRight {
  max-width: 820px;
  min-width: 450px;
  min-height: 910px;
  width: 50%;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileDreamCircle {
  margin-top: 25px;
}

.ProfileMorePictures {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 80px;
}

.ProfileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.ProfileNameAge {
  margin-top: 0;
}

.ProfileHeadingWithIcon {
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.ProfileMentors {
  margin-top: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.ProfileEditButtonContainer {
  margin-top: 50px;
  margin-bottom: 30px;
}

.ProfileSoundBar {
  margin-top: 14px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .ProfileProfileImage {
    visibility: hidden;
  }
  .ProfileMorePictures {
    margin-top: 40px;
  }
  .ProfileBoxLeft {
    height: 810px;
    min-height: 810px;
  }
  .ProfileBoxRight {
    min-height: 810px;
  }
}

@media (max-width: 900px) {
  .ProfileContent {
    margin-left: 0;
  }
  .ProfileContentRow {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .ProfileCorner {
    visibility: hidden;
  }
  .ProfileMorePictures {
    margin-top: 20px;
  }
  .ProfileBoxLeft {
    min-width: 320px;
    height: 610px;
    min-height: 610px;
  }
  .ProfileBoxRight {
    min-width: 320px;
  }
}