.ProfileFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1760px;
}

.ProfileFooterSaveCancelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ProfileFooterCancelButton {
  padding-left: 20px;
}