@import url('../../config/colors.css');

.CheckboxContainer {
  margin: -10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.CheckboxAroundState {
  width: 10px;
  height: 10px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-white-absolute);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--colors-grey-light);
  cursor: pointer;
}

.CheckboxState {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.CheckboxActive {
  background-color: var(--colors-primary-default);
}

.CheckboxText {
  font-size: 16px;
  margin-left: 14px;
  text-align: left;
  color: var(--colors-black-default);
  font-family: 'Nunito-Regular';
}

@media (max-width: 1560px) {
  .CheckboxAroundState {
    width: 6px;
    height: 6px;
  }
}