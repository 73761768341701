@import url('../../config/colors.css');

.AnimatedProgressBarContainer {
  height: 2px;
  border-radius: 1px;
  display: flex;
  align-items: stretch;
  background-color: var(--colors-grey-lighter);
}

.AnimatedProgressBarBar {
  height: 2px;
  /* width: 40px; */
  border-radius: 1px;
  background-color: var(--colors-primary-default);
}
