@import url('../../config/colors.css');

.SpinnerContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Spinner {
  max-width: 50%;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 26px;
  padding-bottom: 26px;
  color: var(--colors-black-absolute);
  background-color: var(--colors-white-default);
  box-shadow: 0px 3px 6px var(--colors-grey-dark);
  border-radius: 10px;
}

.SpinnerText {
  font-family: 'Nunito-Bold';
  font-size: 14px;
  margin: 0;
}
