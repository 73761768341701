@import url('../../config/colors.css');

.ChatContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.ChatCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.ChatProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ChatHistoryButton {
  position: absolute;
  right: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}

.ChatHistoryIcon {
  margin-right: 20px;
}

.ChatHistoryText {
  font-family: 'Nunito-Light';
  font-size: 20px;
  box-shadow: inset 0 0px 0 white, inset 0 -1px 0 var(--colors-secondary-default);
  color: var(--colors-secondary-default);
}

.ChatList {
  flex-grow : 1;
  display: flex;
  flex-flow: column;
}

.ChatInput {
  height: 48px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.ChatBottomSpace {
  height: 20px;
}

@media (max-width: 900px) {
  .ChatContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .ChatCorner {
    visibility: hidden;
  }
  .ChatHistoryText {
    font-size: 14px;
  }

  .ChatInput {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1400px) {
  .ChatProfileImage {
    visibility: hidden;
  }
}