.AdmissionResultContainer {
  background-color: var(--colors-white-default);
  min-height: 100vh;
}

.AdmissionResultCorner {
  position: absolute;
  width: 360px;
  right: 0px;
  z-index: 1;
}

.AdmissionResultLevelText {
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  min-height: 67px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito-Regular';
  font-size: 20px;
  line-height: 30px;
  color: var(--colors-black-default);
}

.AdmissionResultDetails {
  display: flex;
  flex-direction: row;
  background-color: var(--colors-white-absolute);
  width: 70%;
  transform: translateX(-5%);
  min-height: 67px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
}

.AdmissionResultButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  transform: translateX(-5%);
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

@media (max-width: 900px) {
  .AdmissionResultDetails {
    flex-direction: column;
    width: 100%;
    transform: translateX(0%);
  }
  .AdmissionResultLevelText {
    width: 100%;
    transform: translateX(0%);
  }
  .AdmissionResultCorner {
    visibility: hidden;
  }
  .AdmissionResultButton {
    width: 100%;
    transform: translateX(0%);
  }
}
