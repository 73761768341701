@import url('../../config/colors.css');

.SubscriptionContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.SubscriptionCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.SubscriptionActive {
  margin-top: 20px;
  min-height: 80px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SubscriptionActiveStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.SubscriptionActiveCircle {
  border-radius: 100%;
  min-width: 10px;
  min-height: 10px;
  background-color: var(--colors-status-selected);
  margin-left: 28px;
  margin-right: 8px;
}

.SubscriptionActiveText {
  font-family: 'Nunito-Regular';
  font-size: 14px;
  color: var(--colors-grey-text);
}

.SubscriptionActiveCancel {
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 28px;
  padding-left: 5px;
}

.SubscriptionContent {
  margin-top: 20px;
  min-height: 500px;
  width: 65%;
  margin-left: 8%;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  border-radius: 8px;
}

.SubscriptionContentCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SubscriptionDescription {
  margin: 0;
  padding-top: 32px;
  padding-bottom: 20px;
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: var(--colors-secondary-default);
}

@media (max-width: 900px) {
  .SubscriptionCorner {
    visibility: hidden;
  }

  .SubscriptionActive {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }

  .SubscriptionContent {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
}
