@import url('../../config/colors.css');

.TitleBar {
  display: flex;
  flex-direction: column;
  min-height: 88px;
  width: 65%;
  margin-left: 8%;
  border-radius: 7px;
  background-color: var(--colors-white-absolute);
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  position: relative;
}

.TitleBarSmallLeftMargin {
  margin-left: 20px;
  width: 67%;
}

.TitleBarText {
  font-family: 'Nunito-Light';
  font-size: 30px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 25px;
}

.TitleBarLine {
  margin-left: 25px;
  min-height: 1px;
  width: 30px;
  background-color: var(--colors-primary-default);
}

@media (max-width: 900px) {
  .TitleBar {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
    border-radius: 0;
  }
  .TitleBarText {
    margin-left: 40px;
  }
  .TitleBarLine {
    margin-left: 68px;
  }
}