@import url('../../config/colors.css');

.PaginationNumbersContainer {
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.PaginationNumbersNumber {
  font-family: 'Nunito-Bold';
  font-size: 14px;
  margin-top: 0;
  color: var(--colors-primary-default);
}

.PaginationNumbersLastNumber {
  color: var(--colors-secondary-alternative);
}

@media (max-width: 360px) {
  .PaginationNumbersContainer {
    padding-left: 10px;
  }
}

