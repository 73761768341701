@import url('../../config/colors.css');

.ChatHistoryContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.ChatHistoryCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
}

.ChatHistoryProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

@media (max-width: 900px) {
  .ChatHistoryContainer {
    width: 100%;
    margin-left: 0;
    padding-left: 0px;
  }
  .ChatHistoryCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .ChatHistoryProfileImage {
    visibility: hidden;
  }
}