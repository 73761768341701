.PhotoCircleListContainer{
  width: 100%;
  height: 100%;
}

.PhotoCircleListPhotosContainer {
  margin-top: 50px;
  margin-left: 20px;
  width: 90%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}