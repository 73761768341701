@import url('../../config/colors.css');

.AdmissionStartContainer {
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.AdmissionStartBackground {
  background-image: url('../../assets/images/background-login.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 960px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.AdmissionStartFormContainer {
  margin-top: -70px;
  min-width: 320px;
}

.AdmissionStartSentence {
  margin-bottom: 10px;
  font-family: 'Nunito-Regular';
  font-size: 18px;
  color: var(--colors-black-absolute);
  text-align: center;
}

.AdmissionStartButtons {
  margin-top: 40px;
}

.AdmissionStartButtons button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: block;
}
