@import url('../../config/colors.css');

.ProfileEditBackButton {
  position: absolute;
  top: 125px;
  left: 30px;
}

.ProfileEditContainer {
  background-color: var(--colors-white-default);
  height: 100%;
  position: relative;
}

.ProfileEditCorner {
  position: absolute;
  width: 400px;
  right: 0px;
  z-index: 1;
}

.ProfileEditProfileImage {
  position: absolute;
  top: 105px;
  right: 70px;
  z-index: 2;
}

.ProfileEditSubtitle {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  color: var(--colors-grey-dark);
  font-family: 'Nunito-Light';
  font-size: 12px;
}

.ProfileEditSubtitleRed {
  color: var(--colors-status-danger);
  padding-right: 3px;
}

.ProfileEditSubtitleText {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .ProfileEditCorner {
    visibility: hidden;
  }
}

@media (max-width: 1400px) {
  .ProfileEditProfileImage {
    visibility: hidden;
  }
}