@import url('../../config/colors.css');

.ProfileInputContainer {
  width: 100%;
  position: relative;
}

.ProfileInputInnerContainer {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.ProfileInputTextInput {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border-color: transparent;
  border: 1px solid var(--colors-primary-default);
  font-family: 'Nunito-Regular';
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  outline: none;
}
.ProfileInputTextInput::placeholder {
  color: var(--colors-grey-light);
}

.ProfileInputDeleteButton {
  position: absolute;
  right: 50px;
  top: 10px;
  outline: none;
  background-color: transparent;
  border: none;
}

.ProfileInputDeleteIcon {
  width: 15px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
}

.ProfileInputRequired {
  color: var(--colors-status-danger);
  font-family: 'Nunito-Light';
  font-size: 20px;
  position: absolute;
  right: 24px;
  top: -26px;
}