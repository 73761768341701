@import url('../../config/colors.css');

.PersonalInformationEditorContainer {
  display: flex;
  flex-direction: column;

}

.PersonalInformationEditorHeading {
  margin-bottom: 14px;
}

.PersonalInformationEditorInput {
  margin-top: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.PersonalInformationEditorInput > * {
  margin: 10px 0;
}

.PersonalInformationEditorDatePickerContainer{
  height: 40px;
  border-radius: 20px;
  border-color: transparent;
  border: 1px solid var(--colors-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
}

.PersonalInformationEditorDatePicker {
  display: flex;
  border: none;
  font-family: 'Nunito-Regular';
  width: 90px;
  font-size: 12px;
  background-color: var(--colors-white-absolute);
  color: var(--colors-black-default);
  text-align: center;
  outline: none;
}

.PersonalInformationEditorDatePicker::placeholder {
  color: var(--colors-grey-light);
}

.PersonalInformationEditorDateIcon {
  position: absolute;
  left: 27px;
  top: 10px;
}