@import url('../../config/colors.css');

.ButtonPlayButton {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.ButtonPlayContainer {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-secondary-alternative);
}

.ButtonPlayContainerAlternative {
  background-color: var(--colors-primary-default);
}

.ButtonPlayIcon {
  width: 20px;
  height: 20px;
}