.__react_modal_image__header {
  background-color: transparent !important;
  top: 10px !important;
  right: 10px !important;
}

.__react_modal_image__icon_menu svg {
  width: 40px;
  height: 40px;
}

.ModalImageArrowsContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  pointer-events: none;
}

.ModalImageArrowLeft {
  position: absolute;
  z-index: 1001;
  left: 20px;
  top: calc(50% - 15px);
  width: 50px;
  height: 30px;
  background-color: transparent;
  color: white;
  font-family: 'Nunito-Bold';
  font-size: 40px;
  cursor: pointer;
  pointer-events: all;
}

.ModalImageArrowRight {
  position: absolute;
  z-index: 1001;
  right: 20px;
  top: calc(50% - 15px);
  width: 50px;
  height: 30px;
  background-color: transparent;
  color: white;
  font-family: 'Nunito-Bold';
  font-size: 40px;
  cursor: pointer;
  pointer-events: all;
}

.ModalImageNoSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}