@font-face {
  font-family: 'Nunito-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Light'),
       url('./assets/fonts/Nunito-Light.ttf') format('truetype')

}

@font-face {
  font-family: 'Nunito-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Bold'),
       url('./assets/fonts/Nunito-Bold.ttf') format('truetype')

}

@font-face {
  font-family: 'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Regular'),
       url('./assets/fonts/Nunito-Regular.ttf') format('truetype')

}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MenuPage {
  margin-left: 123px;
}
