.ChatHistoryListContainer {
  width: 65%;
  margin-left: 8%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .ChatHistoryListContainer {
    width: 100%;
    margin-left: 0;
  }
}