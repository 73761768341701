@import url('../../config/colors.css');

.ProfileInfoEditContainer {
  margin-left: 8%;
}

.ProfileInfoEditRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin:0 -10px;
  margin-right: 10px;
}
.ProfileInfoEditRowFirst {
 padding-top: 10px;
}

.ProfileInfoEditBox {
  min-width: 470px;
  min-height: 400px;
  width: 470px;
  height: 400px;
  margin: 10px 10px;
  box-shadow: 0px 3px 6px var(--colors-black-ultratransparent);
  background-color: var(--colors-white-absolute);
}

.ProfileInfoEditBoxSecond {
  min-height: 375px;
  height: 375px;
}

.ProfileInfoEditBoxFlexHeigth {
  height: auto;
}

.ProfileInfoBoxContent {
  width: 100px;
  height: 100px;
}

@media (max-width: 900px) {
  .ProfileInfoEditContainer {
    margin-left: 0;
  }
  .ProfileInfoEditRow {
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 660px) {
  .ProfileInfoEditBox {
    min-width: 320px;
    width: 80%;
  }
}
